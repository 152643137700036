import React from 'react'
import FooterAr from '../../utilities/FooterAr'
import Navbar from '../../utilities/Navbar'
import MainSliderAr from '../../components/ar/Home/MainSliderAr'
import InfoAr from '../../components/ar/Home/InfoAr'
import OurProductsAr from '../../components/ar/Home/OurProductsAr'
import TestimonialsAr from '../../components/ar/Home/TestimonialsAr'
import { Helmet } from "react-helmet";


const HomeAr = () => {
  return (
    <main>
      <Helmet>
            <meta charSet="utf-8" />
            <title>Adam Superfoods | الرئيسية</title>
      </Helmet>
        <Navbar/>
        <MainSliderAr/>
        <InfoAr/>
        <OurProductsAr/>
        <TestimonialsAr/>
        <FooterAr/>
    </main>
  )
}

export default HomeAr