import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../utilities/Navbar";
import Footer from "../../utilities/Footer";
import MolassesImg from "../../images/imgs/molasses-bg.webp";
import Pharaohs from "../../images/imgs/pharaohs.webp";
import Problems from "../../images/imgs/problems.webp";
import MentorMolasses from "../../images/imgs/mentor-molasses.webp";
import MentorImg3 from "../../images/imgs/mentor-molasses-3.webp";
import HealthBenefits from "../../images/imgs/health-benefits.webp";
import Exel from "../../images/imgs/exel.webp";
import WorkoutMolasses from "../../images/imgs/workout-molasses.webp";
import Table from "../../images/imgs/table-arabic.webp";
import Story from "../../images/imgs/story.webp";
import Chart from "../../images/imgs/chart.webp";
import Superior from "../../images/imgs/superior.webp";
import DontWait from "../../images/imgs/don't-wait.webp";
import Amazing2 from "../../images/imgs/amazing-2.webp";
import Img3 from "../../images/imgs/img-3.webp";
import Fitness from "../../images/imgs/fitness.webp";
import Beauty from "../../images/imgs/beauty.webp";
import Mother from "../../images/imgs/mother.webp";
import SpecialPrivilege from "../../images/imgs/special-privilege.webp";
import Mix1 from "../../images/imgs/mix-1.webp";
import Mix2 from "../../images/imgs/mix-table.webp";
import AdamGold from "../../images/imgs/adam-gold.webp";

import { Helmet } from "react-helmet";

const MolassesAr = () => {
  return (
    <>
      <Navbar />
      <div className="about-page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Adam Superfoods | دبس أدم</title>
        </Helmet>
        <div className="about-container">
          <div className="about-intro">
            <div className="container">
              <div className="intro-info ar">
                <h3>دبس أدم</h3>
                <p>هل أنت مستعد لإكتشاف سر القوة الخارقة لأجدادنا
                  <br/>
                  الفراعنة؟
                </p>
                <div className="work-with">
                  <Link to="">أكتشف الأن</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section one */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-content">
                <h3>دبس القصب - أقوى سوبر فود على الإطلاق</h3>
                <p>
                  في عالمنا اليوم، أصبح يعاني الغالبية العظمي من البشر علي أقل
                  تقدير بأحد تلك المشاكل الصحية مثل السمنة والضغط والسكري والضعف
                  العام والتهابات القولون أو المفاصل وضعف النظر وهشاشة العظام
                  والأنيميا بالإضافة الي الانتشار المخيف للسرطانات. وظللنا لعقود
                  نتداوى بأدوية لتلك الأمراض المزمنة دون جدوى وظلت علاجات مؤقتة
                  مثل المسكنات. وذلك لأنها كانت لا تعالج سوي أعراض المرض دون
                  النظر لأسبابه العميقة. فمريض السكري يتناول الأنسولين دون أن
                  يعالج سبب المرض سواء كان مشكلة بالكبد والبنكرياس مثل الكبد
                  الدهني أو مقاومة الأنسولين، ومريض الضغط يتناول ادوية الضغط لما
                  بقي من حياته دون أن يعالج السبب والذي من الممكن أن يكون ببساطة
                  فقط نقص المغنيسيوم أو البوتاسيوم أو التهاب ما بجسمه يزيد من
                  التوتر لديه فيرفع ضغط الدم لديه.
                </p>
                <p>
                  وبعد ان تم كشف الغطاء عن السبب الحقيقي للأمراض والمشاكل الصحية
                  المختلفة المتمثل بافتقار الجسم لبعض العناصر الغذائية الهامة
                  وعادات الأكل السيئة وحاولنا اصلاح تلك المشكلات عبر اتباع
                  الحميات الغذائية الصحية، وجدنا المفاجئة، فاصطدمنا بأكبر عائق
                  في طريق الحل وهو انتشار التلاعب البشري بالصفات الوراثية بمصادر
                  طعامنا بهدف زيادة الإنتاج النباتي والحيواني والذي أفقد طعامنا
                  قيمته، فأصبح الحصول على كامل احتياجنا اليومي من العناصر
                  الغذائية من الطعام وحده ضربًا من الخيال. ولكن، هل تعتقد أنك في
                  مأمن انت واسرتك من مخاطر نقصان تلك العناصر بتناول الأطعمة
                  العضوية أو استخدام المكملات الغذائية؟
                </p>
                <p>
                  مفاجأة! أنت بالفعل تستهلك الأسوأ دون أن تدري بشكل متكرر يوميا،
                  نعم تلك المواد المكررة مثل السكر الأبيض والدقيق وزيوت القلي،
                  فقط سعرات خالية من أي قيمة غذائية، بل وتستنزف العناصر المتبقية
                  في جسمك وبتأثير سلبي مضاعف على نسبة العناصر الحيوية المتوفرة
                  بجسمك. هذا وبالإضافة الى النقص الحاد بعدد سعرات الثبات اليومية
                  عما كان عليه في الماضي نتيجة لأسلوب الحياة المعاصر، مما ضاعف
                  الفجوة بين كمية السعرات والعناصر الغذائية التي نحتاجها. وهذا
                  هو السبب الأول والأكثر شيوعا لفشل أي نظام غذائي، حيث أصبحت بين
                  خيارين، وهما اما ان توفي لجسمك احتياجاته الغذائية ولكن بتلك
                  الحالة ستضطر لتخطي سعرات الثبات اليومية بأضعاف، واما ان تحافظ
                  على مدخول سعرات يومي أقل من سعرات الثبات ولكن مع ذلك اختيار
                  فإنك لن تخسر من وزنك سوي لأقل من 5 أيام حتي تنهار حالتك الصحية
                  ومعدل حرقك لتجد بالنهاية ان هذا النظام الذي تتبعه أصبح يزيد من
                  وزنك بدلا من أن يضبطه وان ما فقدته بأول 5 أيام تلك لم يكن سوي
                  ماء.
                </p>
                <p className="gold">
                  لكن ماذا لو كان هناك طعام خارق يمنحك دفعة هائلة من الفيتامينات
                  والمعادن ومضادات الأكسدة سهلة الامتصاص في ملعقة واحدة فقط
                  منخفضة السعرات ؟
                </p>
                <p>
                  هناك عدد قليل للغاية من الأطعمة التي تحتوي على الكثير من
                  العناصر الغذائية سهلة الامتصاص بتركيزات عالية، والتي يُطلق
                  عليها "سوبر فود". يعتبر دبس القصب أقوي تلك الأطعمة على الاطلاق
                  واكثرهم تأثيرا بحياة مستهلكيه ليضمن لكل رياضي وكل أم راحة
                  باليهما نحو تلبية احتياجات اجسادهما وأجساد أطفالهما من العناصر
                  الغذائية يوميا بغض النظر عن باقي الأطعمة المتناولة على مدار
                  اليوم دون أي قلق من الاستخدام طويل المدي على عكس المكملات
                  الصيدلانية المحضرة معمليا. والمبهر أكثر، هو قدرته الخيالية علي
                  تحسين قدرة الجسم على امتصاص العناصر الغذائية من باقي الأطعمة
                  واستغلالها جيدًا. لذلك فهو أكثر من مجرد غذاء أو دواء، بل هو
                  هدية إلهية مناسبة تمامًا لحياتنا الحديثة.
                </p>
                <h3>!دبس منتور هو الحل</h3>
                <p>
                  <span>
                    بديل آمن للسكر فائق الكثافة والتركيز بالفيتامينات والمعادن
                    ومضادات الأكسدة، يساعد على ضبط الوزن والشعور بالطاقة
                    والحيوية:
                  </span>
                  فنجان من القهوة أو مشروب الكاكاو المحلي بملعقتين من دبس منتور
                  يحتوي على قيمة غائية تعادل ما يحتويه نصف كيلو من اللحم أو عشر
                  شرائح من الكبد وجالون من السلطة في آن واحد وبسعرات أقل من نصف
                  ما يحتويه نفس الفنجان إذا كان محلي بالسكر الأبيض.
                </p>
                <p>
                  <span>يدعم صحة الجسم بشكل كامل:</span>
                  من المناعة إلى الطاقة، ومن الهضم إلى صحة العظام والجهاز العصبي
                  والعضلي، ومن الرشاقة الي الأداء الذهني ومعدل الذكاء.
                </p>
                <p>
                  <span>طبيعي وآمن:</span>
                  طبيعي ونقي 100% وخالٍ من أي إضافات أو مواد حافظة او مواد
                  مُعدلة وراثياً كما يُناسب جميع أفراد العائلة، بما في ذلك
                  الأطفال والنساء الحوامل والمرضعات والمسنين.
                </p>
                <p>
                  <span>سهل الاستخدام:</span>
                  يُمكن تناوله بسهولة مباشرة من العبوة كروتين يومي صباحي أو
                  استخدامه كبديل مفيد للسكر لتحلية المشروبات أو لتحضير الحلويات.
                </p>
                <p>
                  دبس القصب منتور Mentor cane molasses هو مادة سحرية تنتج عن
                  تركيز عصير القصب بمعدل تركيز القيمة الغذائية المتاحة ب 1000
                  كجم من القصب إلى أقل من 25 كجم من الدبس. لتغنيك الملعقة
                  الواحدة عن حفنة من المكملات الغذائية. كما يتمتع بقدرة مذهلة
                  على إعادة ضبط الجسم إلى الحالة المثالية من خلال توفير جميع
                  احتياجات الجسم بالنسب المثالية، فضلاً عن سهولة دمجه في نمط
                  حياتنا اليومية دون التأثير على نمط حياتنا.
                </p>
                <p>
                  وعلى عكس المكملات الغذائية الصيدلانية، فإن دبس منتور آمن
                  تمامًا وليس له أي آثار جانبية ضارة. بل وعلى النقيض تماما، نحن
                  هنا نطلق عليها فوائد جانبية مذهلة تزداد مع الاستمرار.
                </p>
                <p className="gold">
                  "دبس القصب منتور" من آدم هو أكثر من مجرد طعام، إنه مفتاح تحرير
                  صحتك وصحة عائلتك من قيود العصر الحديث. ابدأ رحلتك نحو الصحة
                  المثالية اليوم!
                </p>
              </div>
              <div className="paragraph-img">
                <img src={MolassesImg} alt="about-section" />
              </div>
            </div>

            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={Pharaohs} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>
                  هل أنت مستعد لإكتشاف سر القوة الخارقة لأجدادنا الفراعنة؟
                </h3>
                <p>
                  السر الذي لا يعرفه سوي القليلون، يكمن في ذلك السائل الأسود
                  الغامض، اكسير عجيب يمتلك قدرة مدهشة على تحسين جميع وظائفنا
                  الحيوية والعقلية بلمسة سحرية. نعم، إنه دبس القصب، الكنز الخفي
                  الذي وصل إليك الآن لتكتشفه معنا.
                </p>
                <p>
                  نعلم جميعاً أن الزراعة كانت أول حرفة مارسها الإنسان في
                  الحضارات القديمة، وأن التعدين كان الثاني، في نهاية العصر
                  الحجري. ولكن هل كنت تعلم أن أول من قام بالتعدين لم يكن
                  إنساناً؟ نعم، فأول من قام بالتعدين هو النبات نفسه.
                </p>
                <p>
                  في رحلة عبر الزمان والمكان لتكتشف لك الاكسير الفضائي، حيث كان
                  يتم تصنيع وإنتاج ذرات العناصر المختلفة في قلب نجم هائل حتى بلغ
                  أجله وانفجر ليفرج عن كامل منتجاته كسحابة من الغبار الكوني
                  لينشأ منها نظامنا الشمسي. وبعد ان تشكلت الأرض وتوزعت تلك
                  المعادن مكونة للصخور والجبال على صورة املاح معدنية، وعلى مدار
                  مئات الملايين من السنين، نحتت الأمطار هذه المعادن من أعالي
                  جبال إفريقيا، وحملتها إلى مصر عبر نهر النيل، مشكلة مستودعاً
                  هائلاً لهذه العناصر الثمينة على ضفاف وادي النيل. وفي مشهد يعجز
                  عنده العقل، قامت جذور نبات القصب بأول عملية تعدين مثالية
                  بامتصاصها لهذه المعادن من التربة كما تمتص الماء، لتكوّن بها
                  مصفوفة مذهلة تحتوي على جميع المعادن والفيتامينات والأحماض
                  الأمينية والسكريات بنسبٍ متوازنةٍ تُدهش العقول، وكأنها مخصصة
                  ومفصلة لعصرنا الحالي.
                </p>
                <p>
                  لم يغب عن ذكاء الفراعنة عظمة هذا الكنز المخبأ في أراضيهم.
                  وأدركوا ان هذا النبات يحمل في طيّاته سرًا إلهيًا. فبدأوا بغلي
                  عصير القصب وتركيزه، ليُنتجوا سائلًا أسودًا وكثيفا ذا طعمٍ حلو
                  وعميق ليعطيهم قوةٍ هائلة. وسرعان ما ذاع صيت هذا "الإكسير"
                  كعلاجٍ طبيعيٍّ لشتّى الأمراض، فكان بمثابة منحة شفاءٍ من الآلهة
                  حتى اعتقدوا أن الموتى الصَّالحين يعيشون في نعيم دائم في حقول
                  هائلة من القصب تمتد على مد البصر، أطلقوا عليها اسم "آرو"
                  (الجنة في الميثولوجيا المصرية القديمة).
                </p>
                <p>
                  إلا أنه مع مرور الوقت، تم تجاهله كمحلي لصالح السكر الأبيض
                  المكرر والذي تسبب في انتشار العديد من الأمراض، واخفاء قيمته
                  وقدراته الصحية الهائلة عمدا لصالح شركات الأدوية العالمية. ومع
                  ذلك، ظل دبس القصب موجودًا دائمًا، ككنز مخفي ينتظر أن يُكتشف من
                  جديد.
                </p>
                <p>
                  وها نحن هنا الآن لنعيد تقديمه بشكل يناسب نمط حياتنا العصري
                  واحتياجاتنا تحت علامتنا التجارية Mentor لتكون مرشدكم نحو
                  الوصول لنسختك المثالية بأذكى وأسهل وأكثر الطرق فعالية، جئنا
                  لنقدمه بطعم وجودة لا تقاوم، في أنقى صوره، خامًا 100٪، ومعالج
                  فقط بطرق فيزيائية صحية، دون أي تدخل كيميائي أو حرارة عالية من
                  شأنها التأثير على عناصره الغذائية وبكمية سعرات أقل وتركيز أعلي
                  للعناصر الغذائية.
                </p>
                <p className="gold">
                  هذه ليست مجرد قصة تاريخية، بل هي دعوة لاستكشاف قوة هذا السائل
                  الأسطوري. أكمل القراءة للنهاية لتكتشف معنا السر المخبأ عنك،
                  واكتشف كيف يمكن لهذا السائل الأسود الغامض أن يمنحك قوة وصحة
                  ورفاهية تتحدى الزمن.
                </p>
                <img className="img-full-width" src={Story} alt="story" />
                <p>
                  رحلة بدأت منذ مليارات السنين في قلب النجم الأم لمجموعتنا
                  الشمسية، تاركًا وراءه غبارًا لنولد منه. رحلة استمرت على
                  كوكبنا، شهدت ميلاده واكتماله، وشهدت نهر النيل وهو يحمل معه
                  المعادن من أعالي جبال إفريقيا لآلاف الأميال.
                </p>
                <p>
                  واليوم، تأتي أنت لتكون آخر محطة في هذه الرحلة المذهلة. وفي تلك
                  اللحظة حين تتذوقه وتشعر بانتشار طاقته في جسدك، تدرك أنك جزء من
                  رحلة بدأت منذ مليارات السنين. لتتحول تلك الملعقة الصغيرة إلى
                  مفتاحٍ سحريٍّ يُطلق العنان للقوتك الخارقة بداخلك.
                </p>
                <p>
                  مع كل ملعقة، تتحول إلى نسخة أقوى من نفسك. تزداد صحتك، تتحسن
                  وظائفك البدنية والذهنية، وتزداد طاقتك ونشاطك. لتعيش تجربة
                  مليئة بالسحر والجمال، تجربة تربطك بجذورك وتمدك بالقوة لمواجهة
                  كل يوم بحيوية وتحدي. اكتشف السحر، عيش التجربة لتصل للسر الغامض
                  الذي لا يعرفه سوي القليل. اكسير شباب دائم يجمع في طياته تاريخ
                  وحاضر ومستقبل الصحة والقوة.
                </p>
                <p className="gold">
                  دبس منتور هو رحلةٌ ملحميةٌ تُروى على لسان كلّ ذرةٍ منه. كن جزء
                  من تلك الرحلة، واكتشف القوة الخارقة التي بداخلك!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section Two */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph left">
              <div className="paragraph-content">
                <h3>أنواع دبس القصب المختلفة حول العالم</h3>
                <p>
                  يتميز عالم دبس قصب السكر بتنوع كبير في درجات الدبس، مما يعكس
                  طرق الإنتاج المختلفة والاستخدامات النهائية لهذا المكون متعدد
                  الاستخدامات. حيث يتم تقطير بعض أنواع دبس قصب السكر لإنتاج
                  الكحول، كما يستخدم البعض الآخر في صناعة الأدوية، حتى أن بعض
                  الدرجات تستخدم لتقوية الخرسانة وتعزيز صلابة سبائك الصلب
                  ومقاومتها للتآكل. ولكن تعتبر أرقي درجات الدبس تلك التي تُصنف
                  للاستهلاك الآدمي والتي تنقسم أيضا إلى فئتين رئيسيين: الدبس
                  العادي - الدبس الفاخر.
                </p>
                <h5>i. الدبس العادي:</h5>
                <p>
                  لم يظهر ذلك النوع الا بعد ان نشأت صناعة السكر الأبيض، ويعتبر
                  منتجاً ثانوياً لعملية إنتاج السكر. وهو السائل المتبقي بعد
                  استخلاص السكر، بعد مرور عصير القصب المركز بجميع مراحل إنتاج
                  السكر. وهذا يعني أنه قد تعرض للعديد من الإضافات المستخدمة لفصل
                  السكر، بما في ذلك حمض الهيدروكلوريك، ولبن الجير، والكربون
                  المنشط، وراتنجات التبادل الأيوني، وهيدروكسيد الصوديوم، وعمليات
                  الطرد المركزي العنيفة. والذي يتسبب بفقدان الدبس لمعظم قيمته
                  الغذائية وتحميله بالعديد من المواد الكيميائية، ويشكل حالياً
                  أكثر من 99% من دبس القصب المتوفر في السوق العالمي. ولذلك، فهو
                  الخيار الأسوأ عند مقارنته بالنوع الفاخر أو كما يطلق عليه (دبس
                  القصب كامل النكهة). ينقسم هذا النوع إلى ثلاث فئات:
                </p>
                <p>
                  <span>1. الدبس الخفيف Light Molasses:</span>
                  يتميز بقوامه الأقل لزوجة من بين أنواع الدبس وبطعم حلو جازع قد
                  يشعرك بحرقة بحلقك من شدة حلاوته، يستخدم عادة في تحضير الحلويات
                  ولتحلية المشروبات كبديل طبيعي للسكر المكرر ولا يمكن استخدامه
                  كمكمل غذائي لارتفاع نسبة السكريات به.
                </p>
                <p>
                  <span>2. الدبس الغامق:</span>
                  لونه غامق وقوامه اكثر لزوجة يشبه لزوجة عسل النحل وطعمه أكثر
                  قوة، وأقل حلاوة، يستخدم في الطهي والمخبوزات ولا يمكن استخدامه
                  كبديل للسكر لفقدانه قوته علي التحلية.
                </p>
                <p>
                  <span>3. الدبس الأسودBlackstrap:</span>
                  لونه داكن جدًا وطعمه مر ولاذع وقوامه لزج ومتكتل. يستخدم في
                  الأطباق المالحة أو كعلف للماشية نظراً لصعوبة تناوله لطعمه
                  المر.
                </p>
                <h5>ii. الدبس الفاخر (الدبس كامل النكهة)</h5>
                <p>
                  دبس القصب الفاخر يعد صفوة أنواع الدبس وأكثرها قيمة ويتم إنتاجه
                  عن طريق تركيز عصير القصب مباشرة، وليس كمنتج ثانوي لعملية إنتاج
                  السكر. ويتم إنتاجه باستخدام خطوط إنتاج مختلفة تماماً عن تلك
                  المستخدمة في إنتاج السكر. حيث يتم تركيز عصير القصب فقط، وتتم
                  معالجة دبس القصب الناتج بالأسلوب الخاص بكل منتج وفصل كميات
                  السكر المتبلورة بشكل طبيعي نتيجة زيادة التركيز دون استخدام
                  الكيماويات أو الطرد المركزي العنيف المستخدم بصناعة السكر.
                </p>
                <p>
                  يتميز دبس القصب الفاخر بمحتواه الغذائي الفائق ومذاقه الأكثر
                  جاذبية ورائحته الاكثر عطرية. إلا ان جميع الشركات المنتجة للدبس
                  الفاخر لا تنتج سوي النوع الخفيف منه والذي يعرف بالدول العربية
                  باسم "العسل الأسود" وذلك لصعوبة الوصول به لكثافة أعلي دون
                  التسبب بزيادة مرارته وهذا ما تغلبنا عليه بآدم للمواد الغذائية
                  بنسختها المثالية “Mentor Molasses” - الدبس الوحيد الذي يجمع
                  بين القيمة الغذائية المركزة والطعم الحلو بآن واحد بالعالم
                  أجمع.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Pharaohs} alt="about-section" />
              </div>
            </div>
            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={Problems} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>:مشكلة تلك الأنواع الشائعة</h3>
                <p>
                  <span>• محمّلة بالكيماويات:</span>
                  يخضع القصب وعصيره اثناء عملية انتاج السكر للعديد من المواد
                  الكيميائية بهدف استخلاص أكبر كمية من السكر بشكل اقتصادي مما
                  يعني فقدانه للكثير من القيمة الغذائية وتحميله بالعديد من
                  الكيماويات.
                </p>
                <p>
                  <span>• الاستخدام المحدود لكل نوع:</span>
                  يصعب إيجاد دبس يحقق التوازن بين الحلاوة والقيمة الغذائية
                  المركزة مما يعني اضطرار المستهلك لاستخدام النوع الخفيف
                  لاستخدامه بالتحلية فقط أو لتحضير الحلويات واستخدام نوع اخر
                  لاستخدامه كمكمل غائي وهكذا.
                </p>
                <p>
                  <span>الاضافات:</span>
                  حيث يلجأ أغلب المصنعين الي خلط الدبس بمواد أخري لزيادة الإنتاج
                  والقدرة على المنافسة السعرية، وما ساعد على ذلك، هو قلة وعي
                  الجمهور بقيمة المنتج وزيادة الطلب على المنتجات الرخيصة. كما
                  انه كثيرًا ما يستخدم مواد حافظة مثل ثاني أكسيد الكبريت ولجوء
                  أغلب المنتجين إلى حيلة إضافة السكر الأبيض الي الدبس لتحسين
                  المذاق المر الناتج عن وجود نواتج تحلل السكر من الحرارة العالية
                  والطرد المركزي العنيف المستخدم لاستخلاص أكبر كمية من السكر.
                </p>
                <img className="img-full-width" src={Chart} alt="chart" />
              </div>
            </div>
          </div>
        </section>
        {/* section Three */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph left">
              <div className="paragraph-content">
                <h3>دبس منتور Mentor Molasses – نسختنا المميزة</h3>
                <p>
                  دبس منتور من آدم، ليس مجرد دبس فاخر كامل النكهة، بل هو تجربة
                  فريدة تماماً. فبالرغم من وجود العديد من أنواع الدبس المختفة
                  والمتوفرة عالميًا، إلا أن النسخة الفاخرة الخاصة بآدم هي الأكثر
                  تميزا على الاطلاق.
                </p>
                <p>
                  30 عامًا من الخبرة والإبداع مكنتنا من تقديم النسخة الوحيدة في
                  العالم كله التي تجمع بين الطعم الحلو والقيمة الغذائية المكثفة،
                  من خلال الجمع بين أفضل الأساليب المتبعة بطرق الإنتاج الموروثة
                  والتقنيات المتطورة، استطعنا التغلب على تلك النقطة الحرجة
                  للوصول بالدبس لتركيز عالي للقيمة الغذائية وتقليل نسبة السكر به
                  دون التسبب بحدوث أي مرارة للدبس أو التقليل من قوة التحلية
                  خاصته. كما تتم معالجته فقط فيزيائياً دون أي إضافات أو تسخين
                  عنيف.
                </p>
                <p>
                  (<span>النقطة الحرجة:</span>
                  هي نقطة تعبر عن معدل التركيز والذي ان زاد تركيز الدبس لأعلي
                  منها يبدأ تحول طعم الدبس الي مر على الفور. وهي السبب الأصيل
                  بأن يقتصر انتاج أغلب الشركات المنتجة للدبس الفاخر علي الدبس
                  الخفيف منه، وهذا ما تغلبنا عليه بآدم للمواد الغذائية)
                </p>
                <p>
                  وبسر صنعة فريد، استطعنا تقديم دبس منتور الاستثنائي، تلك النسخة
                  التي تجمع بين نقاط قوة ومميزات كل نوع من أنواع الدبس دون
                  عيوبها بسبب احترافية عملية الإنتاج الاستثنائية لتوفير تجربة
                  فريدة من نوعها. وبحل تلك المعادلة، تتضاعف قوة التأثير الإيجابي
                  لدبس منتور بحياتنا، لتتمكن من زيادة قوتك وتعزيز صحتك وحيويتك
                  مع كل فنجان من مشروبك المفضل. وبدلا من ان تخاف على اطفالك من
                  الحلويات، يمكنك ان تجعلها معزز شامل لصحتهم مع كل قطمة.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={MentorMolasses} alt="about-section" />
              </div>
            </div>

            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={Superior} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>دبس منتور – النسخة المثالية لدبس القصب</h3>
                <p>
                  طعم حلو ولذيذ وقيمة غذائية مكثفة (نصف ملعقة من دبس منتور تعادل
                  قوة التحلية لملعقة سكر كاملة بسعرات أقل من النصف وبقيمة غذائية
                  مكثفة تعادل عدة وجبات صحية).
                </p>
                <p>
                  تكمن النقطة الفارقة في أصل ونقاء دبس منتور وسر الصنعة الذي
                  تنفرد به آدم لتحضيره لتنتج لنا نسخة مميزة تجمع بين القيمة
                  الغذائية المركزة والطعم الحلو بصورة نقية 100% دون أي مواد
                  حافظة أو أي إضافات.
                </p>
                <p>
                  <span>دبس فاخر (كامل النكهة):</span>
                  ينتج دبس منتور نم آدم عن تركيز عصير القصب مباشرة بعناية فائقة
                  وليس كمنتج ثانوي لإنتاج السكر كالأنواع التقليدية، مما يمنحه
                  قيمة غذائية أعلي وأكثر أماناً للاستخدام المتكرر وطعمًا أصيلًا
                  غير متأثر ببقايا عملية استخلاص السكر.
                </p>
                <p>
                  <span>طريقة التحضير المثالية:</span>
                  يتم تحضير دبس منتور باتباع طريقة التحضير الصحية الخاصة بآدم
                  وحدها. يتم معالجة دبس منتور بمصانع آدم فقط بالطرق الفيزيائية
                  الصحية دون أي تدخل كيميائي، تلك الطريقة التي عملت آدم علي
                  تطويرها على مدي عقود من الزمن. والتي تحافظ على كامل قيمته
                  الغذائية وطعمه الحلو سويا، والتقليل من المحتوي السكري دون
                  التقليل من قوة التحلية، كما انه لا يتعرض لحرارة شديدة مثل
                  الأنواع الأخرى، وبالتالي فهو يحتوي على نواتج تحلل السكر التي
                  تضيف النكهة المرة. لقد تم تصميمه بدقة للتأكد من أنه يغطي جميع
                  استخدامات الأنواع المختلفة من الدبس مع خلوه تمامًا من جوانبهم
                  السلبية.
                </p>
                <p>
                  <span>نقي 100 %:</span>
                  خالٍ تمامًا من أي مواد حافظة أو أي منكهات من أي نوع، ولا نساوم
                  على نقائه بإضافة السكر الأبيض كما تفعل بعض العلامات التجارية.
                </p>
                <p>
                  <span> محتواه الغذائي الفائق:</span>
                  يفيض دبس منتور من آدم بكنوز من الفيتامينات والمعادن ومضادات
                  الاكسدة بتركيز فائق، يوفر أكثر من 80% من احتياجات الجسم
                  اليومية لأغلب العناصر الغذائية الأساسية فقط بملعقتين منه. كما
                  يتميز دبس القصب المصري بمحتواه الأغنى بالمغذيات عن باقي أنواع
                  الدبس الأخرى، ليصل تركيز الحديد به الى أعلي من 10 مجم لكل
                  100جرام. في حين يحتوي دبس القصب سواء الاسيوي أو البرازيلي فقط
                  4 مجم من الحديد لكل 100جرام).
                </p>
                <p>
                  <span>&#10003; مؤشر جلايسيمي منخفض:</span>
                  أقل من ثلث الخاص بالسكر، مما يضمن أمانه علي مرضي السكري
                  لاستقرار مستويات السكر في الدم وعلاج مقاومة الأنسولين.
                </p>
                <p>
                  <span>&#10003; خالي من كل ما هو ضار:</span>
                  يتم انتاج دبس منتور باستخدام أجود المحاصيل المصرية تامة النضج
                  والغير معدلة وراثيا. كما أنه خالي من الجلوتين واللاكتوز
                  والنشويات والسكر المضاف.
                </p>
                <p>
                  <span>
                    &#10003; يجمع بين القيمة الغذائية المكثفة والطعم الحلو:
                  </span>
                  يوازن ببراعة بين قوة التحلية والمحتوى الغذائي الغني.
                </p>
                <p>
                  <span>&#10003; نكهة فريدة ولذيذة:</span>
                  يمتاز دبس منتور بنكهة استثنائية تجمع بين الحلاوة والعمق، ويعزز
                  طعم أي طبق عند اضافته ليفتح أبوابًا جديدة على تجارب لا تنسي.
                </p>
                <p>
                  <span>&#10003; سهل الاستخدام والتوظيف:</span>
                  يمكن استخدامه مباشرةً من العبوة صبحا كروتين يومي للاستمتاع
                  بمذاقه اللذيذ وفوائده الصحية كمكمل غذائي شامل على حد سواء، أو
                  استخدام قوته على التحلية لتحلية المشروبات والحلويات بأسلوب
                  صحي، أو الاستمتاع بمذاقه الفريد والعميق كإضافة خاصة أو كصوص
                  فريد أثناء الطهي لإضافة لمسة إبداعية لوصفاتك الحلوة والمالحة
                  على حد سواء. يمكن استخدامه في القهوة والعصائر والحلويات
                  والسلطات والشاورما ومخللات الشواء لإضافة نكهة خاصة وخفض مؤشر
                  نسبة السكر في الدم للوجبة بأكملها
                </p>
                <p>
                  <span>&#10003; خالي من المعادن الثقيلة:</span>
                  ينمو قصب السكر المصري في تربة غنية بالمعادن المفيدة وخالية من
                  المعادن الثقيلة. وذلك بفضل الطبيعة الجيولوجية لوادي النيل،
                  التي تعمل كمرشح طبيعي أثناء تحرك المياه على طول النهر لمسافة
                  تتعدي ال 6000 كيلو متر، مما يؤدي إلى ترسيب المعادن الثقيلة مثل
                  الرصاص والزئبق والكادميوم في دول المنبع، بينما تستمر المياه
                  النقية في التدفق، حاملة المعادن المفيدة فقط بطمي النيل الي ان
                  تصل به المياه الي مصر.
                </p>
                <p>
                  <span>
                    &#10003; متوافق بشكل مثالي مع الفلسفة الخاصة بآدم في تحسين
                    حياة الناس:
                  </span>
                  سوبر فود – فعال ومؤثر بقوة بحياة المستخدمين - له فوائد علاجية
                  ووقائية متعددة - آمن للاستخدام طويل الأمد لمختلف الأعمار – حل
                  فعال وشامل للأهداف الخاصة بكل مستهلك ويسهل توظيفه حسب نوع
                  الهدف – لذيذ ويسهل ادراجه بالحياة اليومية.
                </p>
                <p>
                  <span>دبس منتور</span> هو مُحلي طبيعي صحي ومثالي مليء بالعناصر
                  الغذائية لا يتسبب بزيادة الوزن كما يحدث مع السكر الأبيض، بل
                  يساعد على التحكم في الوزن، وتعزيز مستويات الطاقة، وتحسين عملية
                  التمثيل الغذائي، ودعم الجهاز المناعي، وصحة الكبد والقلب
                  والأوعية الدموية، وتنظيم ضغط الدم، وتطهير الجسم من السموم،
                  وتعزيز الصحة الإنجابية لكل من الجنسين، ودعم النمو الصحي
                  للأطفال، كما يمكن استخدامه لإضافة لمسة سحرية في الوصفات الحلوة
                  والمالحة على حد سواء لزيادة تكرار الحصول على الفائدة على مدار
                  اليوم بأمان تام. أما أفضل شيء، هو عدم تسببه بمرارة أو تغيير
                  بالطعم عند استخدامه بالتحلية كما يحدث مع المحليات الصناعية
                  وذلك لأنه هو الطور الأصلي للسكر الأبيض قبل تكريره وتحويله لسم،
                  لذلك فهو أقرب بدائل السكر طعما للسكر المعتادين عليه وبقوة
                  تحلية أعلي وبقيمة غذائية فائقة الكثافة.
                </p>
                <p className="gold">
                  ميزّ نفسك، واستمتع الطاقة والحيوية وجسد يعمل بأعلى كفاءة!
                </p>
              </div>
            </div>

            <div className="paragraph left">
              <div className="paragraph-content">
                <h3>:دبس مينتور هو الحل الأمثل لكل من</h3>
                <p>
                  - يبحث عن أسلوب حياة صحي وتريد الحصول على الجسم المثالي بشكل
                  أسرع وأكثر صحة دون حرمان.
                </p>
                <p>- الرياضيين بجميع أنواعهم وبمختلف الأهداف.</p>
                <p>
                  - الأشخاص الذين يرغبون في تحسين صحتهم وحيويتهم بشكل عام لتحقيق
                  أهداف مختلفة، سواء كان ذلك بناء كتلة عضلية خالية من الدهون أو
                  فقدان كتلة الدهون دون التأثير سلباً على صحتهم وعملية الأيض في
                  المستقبل.
                </p>
                <p>
                  - مرضي السكري والضغط أو كل من يبحث عن بديل طبيعي وآمن للسكر.
                </p>
                <p>
                  - أي فتاة أو امرأة بشكل عام والحوامل وحديثي الولادة والحائض
                  بشكل خاص.
                </p>
                <p>
                  - أي أم تريد راحة البال والاطمئنان على صحة أطفالها، وتضمن
                  توفيرها لكامل العناصر الغذائية الأساسية التي يحتاجونها يوميا
                  للنمو والتطور.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={MentorImg3} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section Four */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph left">
              <div className="paragraph-content">
                <h3>الفوائد الصحية لدبس منتور - للصحة العامة</h3>
                <p>- تقوية العظام ودعم تمعدنها وزيادة كثافتها.</p>
                <p>- دعم النمو الصحي والوقاية من السمنة لدى الأطفال.</p>
                <p>
                  - تنظيم ضغط الدم وتقوية عضلة القلب ودعم صحة ومرونة الأوعية
                  الدموية.
                </p>
                <p>
                  - علاج مقاومة الأنسولين وتحفيز البنكرياس على افراز الأنسولين
                  للمساعدة في إدارة وعلاج مرض السكري من نوعيه الأول والثاني
                </p>
                <p>- طرد السموم من الجسم! ودعم صحة الكبد والرئتين.</p>
                <p>- تحسين الوظائف الادراكية والذاكرة والتعلم.</p>
                <p>- تقوية الأعصاب وتحسين سرعة رد الفعل وزيادة انتاج الطاقة.</p>
                <p>- تعزيز النشاط والحيوية.</p>
                <p>- تحسين المزاج ودعم إفراز السيروتونين.</p>
                <p>
                  - دعم الجسم بمضادات الأكسدة القوية، والتي لها العديد من
                  التأثيرات الفعالة المضادة للشيخوخة والمضادة للسرطان! بالإضافة
                  إلى خصائصها المضادة للالتهابات، والتي أثبتت فعاليتها الجبارة
                  في علاج مختلف الالتهابات بدءا من التهابات البشرة الي التهاب
                  المفاصل الروماتويدي.
                </p>
                <p>- ضبط التوازن الهرموني.</p>
                <p>
                  - تحسين الصحة الإنجابية لكلا من الجنسين الذكر والأنثى دون أي
                  تضارب، وأكثر من ذلك بكثير!
                </p>
                <h5>للرجال:</h5>
                <p>
                  يحتوي على تركيزات هائلة من الحديد والزنك والنحاس خاصة عند دمجه
                  مع طحينة السمسم، مما يشير الي قدرته الهائلة على تعزيز حجم وصحة
                  الخصيتين عند الرجال وتحفيزها لإنتاج التستوستيرون أو ما يعرف
                  بهرمون الذكورة مما يعزز الكتلة العضلية وحرق الدهون المستمر.
                  كما يعمل محتواه من المغنيسيوم على دعم صحة الأوعية الدموية
                  ومنها الأوعية الدموية الطرفية مما يمددها لاستيعاب كمية أكبر من
                  الدم مما يجعل منه أقوي فياجرا طبيعية لتعزيز القوة الجنسية دون
                  التأثير السلبي على القلب.
                </p>
                <h5>للسيدات:</h5>
                <p>
                  يساعد دبس منتور النساء على التخلص من مستويات الاستروجين
                  الزائدة والتي تسبب الكثير من الأعراض المزعجة مثل السمنة وحب
                  الشباب والشيب المبكر والبشرة الدهنية. ويعوضهم بكامل ما يفقدونه
                  من معادن مع دم الحيض أثناء الدورة الشهرية أو ما يستنزفه الجنين
                  أثناء الحمل. كما يعتبر حل سحري للتخفيف من أعراض انقطاع الطمث
                  لدى النساء، مثل الهبات الساخنة وتقلبات المزاج.
                </p>
                <p className="gold">
                  والمدهش ان تلك الفوائد تزداد بشكل مضاعف مع مرور الوقت عند
                  الحفاظ علي الاستهلاك اليومي**
                </p>
              </div>
              <div className="paragraph-img">
                <img src={HealthBenefits} alt="about-section" />
              </div>
            </div>

            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={Exel} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>دبس منتور - مكمل غذائي طبيعي شامل</h3>
                <p>بما يمتاز دبس منتور عن أي مكمل غذائي آخر؟</p>
                <p>
                  <span>&#10003; تركيبة طبيعية غنية:</span>
                  فقط ملعقتان كبيرتان من دبس فائق الكثافة والتركيز بالفيتامينات
                  والمعادن ومضادات الأكسدة تعادل ما يحتويه نصف كيلو من اللحم أو
                  عشر شرائح من الكبد وجالون من السلطة من مغذيات بصورة سهلة
                  الامتصاص لتوفر لجسمك 80% من احتياجاته اليومية من جميع العناصر
                  الحيوية.
                </p>
                <p>
                  <span>&#10003; بديل طبيعي للمكملات الغذائية:</span>
                  على عكس الحبوب والمكملات المحضرة معمليا، والتي تحتوي على عنصر
                  واحد فقط، والتي قد تكون صعبة على المعدة وتسبب آثاراً جانبية
                  طويلة الأمد بالإضافة على امتلائها بالمواد الكيميائية والمواد
                  الحافظة، فإن دبس منتور هو حل طبيعي ولذيذ يوفر لجسمك جميع
                  العناصر الغذائية التي يحتاجها يوميا دون أي مخاطر.
                </p>
                <p>
                  <span>&#10003; امتصاص أفضل:</span>
                  دبس منتور هو مصدر طبيعي للعناصر الغذائية خالي من الألياف، مما
                  يضمن امتصاصها بشكل أفضل واستخدامها بشكل كامل من قبل الجسم.
                </p>
                <p>
                  <span>
                    &#10003; تعزيز صحة الجهاز الهضمي والتمثيل الغذائي:
                  </span>
                  يساعد دبس منتور على تعزيز صحة الجهاز الهضمي وتحسين قدرة الجسم
                  على امتصاص أفضل للعناصر الغذائية.
                </p>
                <p>
                  <span>&#10003; مكمّل مثالي لنظامك الغذائي:</span>
                  يساعدك دبس منتور على تحقيق أقصى استفادة من طعامك من خلال تعزيز
                  امتصاص العناصر الغذائية والاستفادة منها بشكل أفضل. لتحصل على
                  أقصى استفادة من نظامك الغذائي، حتى لو لم يكن مثاليًا.
                </p>
                <p>
                  <span>&#10003; طبيعي 100%:</span>
                  مكمل شامل وطبيعي خالٍ من المواد الحافظة والمواد الكيميائية
                  الضارة، مما يجعله آمنًا للاستخدام على المدى الطويل.
                </p>
                <p>
                  يضمن تنوع العناصر الغذائية ووجودها بصورة طبيعية وبنسب موزونة
                  بتلك المصفوفة المذهلة على امتصاص الجسم لها بسهولة واستغلالها
                  بشكل فعال، حيث يعمل كل عنصر على تعزيز فوائد العناصر الأخرى
                  وتسهيل عملية امتصاص الجسم لها. فعلى سبيل المثال، يساعد النحاس
                  في امتصاص الحديد، بينما يحتاج الكالسيوم إلى كمية أكبر من
                  المغنيسيوم والفوسفور ليعمل الثلاثي على تمعدن العظام وتحسين
                  كثافة العظام.
                </p>
                <p>
                  وبدون هذا التوازن، فإن مجرد تناول الكالسيوم وحده يمكن أن يكون
                  له آثار سلبية على الجسم مثل تصلب الشرايين أو حصوات الكلي. أي
                  ان هناك عناصر بجانب أدوارها المباشرة هناك أدوار اخري لها مثل
                  توجيه العناصر الأخرى لمكانها الصحيح بالجسم والتعاون معها
                  لتنفيذ مهمة أكبر. ليضمن هذا المزيج المثالي من العناصر الغذائية
                  لكل رياضي وكل أم بالاطمئنان وراحة البال حول تلبية احتياجات
                  أجسامهم وأجسام أطفالهم لمختلف العناصر الغذائية المهمة لوظائفهم
                  الحيوية وضمان امتصاص أجسامهم لها واستغلالها الاستغلال الأمثل،
                  بغض النظر عن بقية الأطعمة التي يتناولونها على مدار اليوم.
                </p>
                <p className="gold">
                  لا تنتظر دقيقة أخرى! اكتشف دبس منتور من اليوم وعيش الصحة
                  والحيوية التي لم تشعر بها من قبل!!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section five */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-content">
                <h3>دبس منتور - مُحلي طبيعي وصحي وحارق للدهون في آن واحد!</h3>
                <p>
                  هل تعاني من زيادة الوزن أو تكافح من أجل إنقاص وزنك؟ هل تبحث عن
                  بديل صحي للسكر الأبيض؟ ولكن عن أي بديل تبحث! هل تبحث عن بديل
                  للسكر خالي من السعرات وستقبل بالبدائل الصناعية وان كان لها
                  تأثير خطير على الصحة؟ أم تبحث عن بديل طبيعي وستقبل بالسعرات
                  العالية وعدم التحكم بسكر الدم؟
                </p>
                <p>
                  لا داعي للبحث بعيدا والقبول بتلك التنازلات، كل ما تبحث عنه
                  هنا، دبس القصب منتور ليس فقط مُحلي طبيعي ذو مؤشر جلايسيمي
                  منخفض ليساعدك على التحكم بمستويات السكر والأنسولين بالدم ومنع
                  ارتفاعهم او انخفاضهم المفاجئ، بل انه أيضا محلي منخفض السعرات
                  ذو قوة تحلية أعلي من الخاصة بالسكر الأبيض ليساعدك على التحكم
                  بوزنك دون حرمان أو آثار صحية سلبية. ليس ذلك فقط، بل ويحسن صحتك
                  وقوتك ويرفع من معدل الحرق لديك لإنتاج الطاقة وزيادة النشاط. لا
                  مزيد من البحث، كل هذه الخصائص مجتمعة في مادة واحدة.
                </p>
                <p>
                  دبس القصب منتور هو أفضل مُحلي على الإطلاق! إنه الطور الأصلي
                  كامل القيمة للسكر والذي استخدمه أجدادنا لتحلية طعامهم وشرابهم،
                  ولا يزال الأفضل!
                </p>
                <p>
                  دبس منتور هو مُحلي طبيعي رائع منخفض السعرات الحرارية! ذو مؤشر
                  جلايسيمي منخفض (50) والذي هو أقل بكثير من المُحليات الأخرى بما
                  في ذلك المالتوديكسترين (150) والجلوكوز (100) وشراب الذرة (75)
                  والسكر الأبيض (65) وعسل النحل (75). يقوم الجسم بامتصاص دبس
                  منتور ببطء مما لا يسبب أي ارتفاع حادة بمستويات السكر في الدم
                  كما تتسبب المُحليات الأخرى، مما يجعله خياراً مثالياً للتحكم في
                  مرض السكري بنوعيه الأول والثاني بآن واحد. كما أنه رائع لفقدان
                  الوزن دون حرمان لأنه غني بالمعادن والفيتامينات التي تعزز عملية
                  الأيض وتقوم بتحسين الوظائف العضلية والعصبية، مما يسمح لك بحرق
                  الدهون بسرعة مضاعفة وتحقيق أهدافك سواء لزيادة مستويات الطاقة
                  والحيوية أو خسارة الدهون في وقت قصير دون التسبب بأي أذي
                  للعضلات وأنسجة الأعضاء او حدوث أي تأثير سلبي علي معدل الحرق
                  عند الاستمرار علي الدايت أيا كان نوعه!
                </p>
                <h5>لماذا السكر الأبيض ضار وما سر علاقته بالسمنة؟</h5>
                <p>
                  بالأول، يجب توضيح معلومة هامة جدا وهي ان السكريات الطبيعية
                  ليست هي الضارة. فالجلوكوز هو الوقود الأساسي للجسم بجانب
                  الدهون، والوقود الوحيد للمخ، وانما الأضرار كلها تتمحور حول
                  السكر الأبيض المكرر.
                </p>
                <p>
                  لم يخلق الله تعالي أي عنصر غذائي بصورة حرة تماما وهذا أيضا
                  ينطبق على السكر، فدائما ما يتواجد السكر بالطبيعة بصورة مرتبطة
                  بالعديد من العناصر الأخرى مثل الألياف والمعادن والفيتامينات
                  مما يبطئ من امتصاص الجسم لها ليصل الدم علي دفعات ولا يتسبب
                  بارتفاع سكر الدم أو الأنسولين بشكل مفاجئ ويحافظ علي الشعور
                  بالطاقة لفترة طويلة، كما تحتاج جزيئات السكر الي المعادن
                  والفيتامينات لتكسيرها تلك وهضمها وتحويلها لطاقة. أما بحالة
                  السكر الأبيض، فالأمر يختلف تماما بعد ان تم تجريده من أي قيمة
                  غذائية وأصبح حرا ليتحول من عنصر هام الي عنصر سام. ويمكننا
                  تلخيص أضرار استخدام السكر الأبيض بالنقاط التالية:
                </p>
                <p>
                  <span>• استنزاف الصحة من الجسم:</span>
                  لا يفتقر السكر الأبيض إلى العناصر الغذائية الأساسية فحسب، بل
                  إنه بالمقابل يستنزف العناصر الغذائية مثل المغنيسيوم وفيتامين ج
                  وفيتامين د والكالسيوم والكروم من الجسم من أجل معالجة السكر
                  المستهلك. تلك العناصر الغذائية المهمة للحفاظ على التحكم في
                  نسبة السكر في الدم.
                </p>
                <p>
                  <span>• انهيار معدل الحرق:</span>
                  يؤدي نقص العناصر الغذائية الضرورية لتصنيع الجسم للهرمونات
                  المختلفة إلى انهيار معدل الحرق وضعف الجهاز العصبي والعضلي.
                </p>
                <p>
                  <span>• ارتفاع سكر الدم:</span>
                  يؤدي استهلاك السكر الأبيض إلى الارتفاع المفاجئ في نسبة السكر
                  في الدم، مما يفتح الباب لتخزين كامل تلك السعرات بمخازن الدهون
                  بدلا من تحويلها لطاقة مما يؤدي إلى الإرهاق والخمول ونقص الطاقة
                  والخمول.
                </p>
                <p>
                  <span>• مقاومة الأنسولين: </span>
                  تؤدي تلك الارتفاعات المفاجئة بسكر الدم وتكرار تلك العملية على
                  مدار اليوم والناتجة عن استهلاك السكر الأبيض إلى مقاومة
                  الأنسولين، مما يضاعف أوقات تخزين الدهون وحالة الخمول والضعف.
                </p>
                <p>
                  وكلما زاد استهلاك السكر الأبيض، زادت حدة الأعراض، مما يؤدي في
                  النهاية إلى انهيار الصحة والانخفاض الحاد بمعدل الحرق ومقاومة
                  الأنسولين الي السمنة المفرطة وأمراض القلب والشرايين والسكري.
                </p>
                <p className="gold">
                  لا تنظر بعيدًا! استبدل الآن السكر بدبس القصب منتور وشاهد
                  الدهون تذوب بعيداً! اختيار ذكي يساعدك على ضبط وزنك وتحسين صحتك
                  دون أي حرمان.
                </p>
                <img
                  className="img-full-width"
                  src={Table}
                  alt="molasses-table"
                />
                <p className="gold">
                  هام* يتم استخدام نصف ملعقة من دبس منتور للحصول علي نفس قوة
                  التحلية لملعقة كاملة من السكر.
                </p>
              </div>
            </div>
            <div className="paragraph left">
              <div className="paragraph-content">
                <h3>ما يميز دبس منتور كمحلي طبيعي لضبط الوزن</h3>
                <p>
                  <span>• سعرات حرارية أقل:</span>
                  ملعقة من دبس منتور تعادل قوة التحلية الخاصة بملعقتين من السكر
                  وبسعرات أقل من ثلث ما تحتويه ملعقتي السكر تلك.
                </p>
                <p>
                  <span>• مؤشر جلايسيمي منخفض (50):</span>
                  الخيار الأمثل لمن يتطلع إلى تجنب الارتفاع المفاجئ في نسبة
                  السكر في الدم. ويبحث عن مصدر لطاقة مستمرة دون أي تعب مفاجئ او
                  خمول.
                </p>
                <p>
                  <span>• علاج لمقاومة الأنسولين:</span>
                  يعتبر دبس منتور مغيرًا حقيقيًا للعبة عندما يتعلق الأمر بحساسية
                  ومقاومة الأنسولين. يساعد دبس منتور على تحسين حساسية الأنسولين
                  بعدة طرق منها المباشر عبر تحكمه بنسبة السكر بالدم والغير مباشر
                  عبر تحسين تحمل الجلوكوز وتحفيز انتاج الطاقة (ATP)، مما يضاعف
                  أثره الايجابي.
                </p>
                <p>
                  <span>• ضمان لوصولك لهدفك:</span>
                  هل عانيت من قبل من عدم التطور نحو هدفك بعد اقل من أسبوعين من
                  اتباعك لنظامك الغذائي وتدهورت صحتك وحيويتك، لست وحدك، وذلك لأن
                  المبدأ الأساسي المتبع لخسارة الوزن عبر وضع نظام غذائي يحتوي
                  على سعرات أقل من ثبات السعرات اليومية.
                </p>
                <p>
                  فيبدأ متخصص التغذية بمنعك من استخدام السكر الأبيض لتقليل تلك
                  السعرات وتقليل كميات الطعام، ونتيجة لذلك يتم استنزاف العناصر
                  المسؤولة عن الحرق وإنتاج الطاقة والوظائف الحيوية المختلفة دون
                  ان تستطيع تعويضها عبر كميات الطعام الضئيلة تلك، لينهار معدل
                  الحرق والصحة العامة، وظلت تلك هي المعضلة الأساسية لأي نظام
                  غذائي. ولكن لاستخدام دبس منتور فائق الكثافة والتركيز
                  بالفيتامينات والمعادن ومضادات الأكسدة كبديل للسكر، سيضمن لك
                  تلبية احتياجات جسمك لمختلف المغذيات التي يحتاجها ليتيح لك حرية
                  التحكم بباقي هامش السعرات لتصل لهدفك بشكل أسرع ومستدام دون
                  الخوف من مشاكل نقص التغذية الشائعة بمختلف أنواع الدايت أو أي
                  أثر سلبي على معدل الحرق الخاص بك مستقبلا كما سيعزز شعورك
                  بالطاقة والحيوية مما يزيد من فرص حرق الدهون بشكل مضاعف عبر
                  الحركة المبذولة.
                </p>
                <p>
                  والأهم من كل ما سبق، أنك لن تضطر ان تحرم نفسك من تحلية مشروبك
                  أو ان تمتنع عن الحلويات، لأنك بدبس منتور ستحولها لحلوي صحية
                  تساعدك على الوصول لهدفك أسرع بعد ان كانت عائق وحائل بينك
                  وبينه.
                </p>
                <p>
                  والأهم من كل ما سبق، أنك لن تضطر ان تحرم نفسك من تحلية مشروبك
                  أو ان تمتنع عن الحلويات، لأنك بدبس منتور ستحولها لحلوي صحية
                  تساعدك على الوصول لهدفك أسرع بعد ان كانت عائق وحائل بينك
                  وبينه.
                </p>
                <p className="gold">
                  والأهم من كل ما سبق، أنك لن تضطر ان تحرم نفسك من تحلية مشروبك
                  أو ان تمتنع عن الحلويات، لأنك بدبس منتور ستحولها لحلوي صحية
                  تساعدك على الوصول لهدفك أسرع بعد ان كانت عائق وحائل بينك
                  وبينه.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Amazing2} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section six */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph left">
              <div className="paragraph-content">
                <h3>:دبس منتور لمرضي السكري من النوع الأول</h3>
                <p>
                  يفضل ويوصي بشدة استخدام دبس منتور من قبل الأشخاص المعرضين
                  للإصابة بداء السكري أو المصابين به، وذلك بفضل مؤشره الجلايسيمي
                  المنخفض نسبياً ومحتواه العالي من مركبات البوليفينول المضادة
                  للأكسدة والتي تساعد على استقرار مستويات السكر في الدم مما يمنع
                  التقلبات في مستويات الأنسولين ونقص سكر الدم. كما يعزز محتوى
                  دبس مينتور من الكروم والكالسيوم والمغنيسيوم من عامل تحمل
                  الجلوكوز وتحفيز البنكرياس علي انتاج الأنسولين مع دعم عمل
                  الإنزيمات المسؤولة عن استقلاب الجلوكوز إلى طاقة.
                </p>
                <p>
                  ولكن من المهم دائما علي مرضي السكر من النوع الأول البدء بكميات
                  قليلة تحت مراقبة الطبيب الخاص، وذلك ليس لمتعلق بالدبس، ولكن قد
                  تكون اجسامهم مفتقرة للعديد من العناصر الغذائية او تعاني من
                  قصور لوظائف ما والتي سيعالجها الدبس مع الاستخدام ولكن من المهم
                  دائما البداية الذكية. وهذا لا ينطبق بنفس الحساسية على مرضي
                  السكري من النوع الثاني.
                </p>
                <p className="gold">
                  ابدأ رحلتك نحو الصحة المثالية مع دبس منتور من آدم!
                </p>
                <h3>:خطوات بسيطة لتحقيق النتائج المرجوة</h3>
                <p>
                  فقط قم باستبدال السكر الأبيض بدبس منتور في جميع وصفاتك
                  وحلوياتك المفضلة وشاهد الفوائد المذهلة!
                </p>
                <h5>النتائج:</h5>
                <p>
                  <span>&#10003;</span> بنهاية الأسبوع الأول من استخدام دبس
                  منتور: ستلاحظ تحسنا مذهلا بالشعور بالشبع وتحسنًا في عملية
                  الهضم.
                </p>
                <p>
                  <span>&#10003;</span> بحلول نهاية الأسبوع الرابع; تحسن مستويات
                  الطاقة بشكل ملحوظ. وزيادة معدل حرق الدهون.
                </p>
                <p>
                  <span>&#10003;</span> مع الاستمرار في استخدام دبس منتور:
                  ستلاحظ تسارع عملية فقدان الوزن دون التسبب بمشكلات جانبية مثل
                  انخفاض الحرق أو الهدم العضلي أو الترهلات أو الخمول والتي
                  غالباً ما تظهر مع الحميات الغذائية القاسية. ليصبح جسمك أكثر
                  صحة ونشاطاً وأقرب إلى شكله وحالته المثالية.
                </p>
                <p className="gold">
                  اكتشف دبس منتور وابدأ رحلتك نحو نسختك المثالية من اليوم!
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Img3} alt="about-section" />
              </div>
            </div>
            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={DontWait} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>دبس منتور الداعم الأقوي للياقة البدنية والأداء الرياضي</h3>
                <p>
                  هل تسعى جاهداً للوصول إلى أفضل نسخةٍ من نفسك، لكنّك تواجه
                  صعوبةً في تحقيق ذلك؟ هل تشعر بالإحباط من عدم قدرتك على تحقيق
                  التقدم المنشود في رحلة اللياقة البدنية؟ هل تبذل جهدًا هائلاً
                  في صالة الألعاب الرياضية دون أن ترى نتائج مرضية؟ هل تعاني من
                  الخمول أو الإرهاق أو بطء التعافي بعد التمرين؟
                </p>
                <p>
                  لا تقلق، لست وحدك! يواجه العديد من الأشخاص صعوباتٍ مماثلة،
                  وذلك لسببٍ رئيسيٍّ: عدم الاهتمام الكافي بالعناصر الغذائية
                  الدقيقة. فمع تركيز المدربين على كمية السعرات الحرارية
                  والبروتين والكربوهيدرات دون مراعاة العناصر الحاسمة التي تُحدد
                  فعالية استغلال الجسم لتلك العناصر.
                </p>
                <p>
                  تُعدّ العناصر الغذائية الصغرى، مثل المعادن والفيتامينات،
                  بمثابة المفتاح لسلوك الجسم تجاه العناصر الكبرى، مثل البروتين
                  والكربوهيدرات. فهي التي تُحدد قوة انقباض العضلات، وتحويل
                  الكربوهيدرات إلى طاقةٍ بدلاً من تخزينها كدهون.
                </p>
                <p>
                  فبدون تلك العناصر الصغرى، لا يستطيع جسمك استخدام البروتين
                  لبناء العضلات، بل قد يُهدمه لتحويله إلى طاقة. ناهيك عن ضعف
                  قدرة الجسم على تحويل الكربوهيدرات إلى طاقة، مما يُؤدّي إلى
                  تخزينها كدهون.
                </p>
                <h5>
                  لذا، هل أنت مستعد للارتقاء بلياقتك البدنية إلى المستوى التالي؟
                </h5>
                <p>
                  دبس القصب الفاخر "منتور" هو الأداة الأقوى والأذكى بلا منافس
                  لمساعدتك على تحقيق أهدافك الرياضية، فباستخدامك لدبس منتور
                  يوميا، ستُلاحظ تحسنًا ملحوظًا في:
                </p>
                <p>
                  <span>• أداءك الرياضي:</span>
                  ستتمكن من ممارسة التمارين بكثافةٍ أكبر، وتحقيق نتائجٍ أفضل في
                  وقتٍ أقصر.
                </p>
                <p>
                  <span>• طاقتك ونشاطك:</span>
                  ستشعر بزيادةٍ ملحوظةٍ في طاقتك ونشاطك تدوم طويلاً دون الشعور
                  بالتعب أو الانهيار، مما يُساعدك على الاستمرار في التدريب دون
                  إرهاق، حيث يدعم إنتاج الطاقة على المستوى الخلوي، مما يحسن من
                  أدائك البدني والذهني.
                </p>
                <p>
                  <span>• الاستشفاء العضلي:</span>
                  ستتعافى عضلاتك بشكلٍ أسرع بعد التمارين، مما يُقلّل من آلام
                  العضلات ويُساعدك على العودة إلى التدريب بشكلٍ أسرع.
                </p>
                <p>
                  <span>• تركيزك:</span>
                  ستتمكن من التركيز بشكلٍ أفضل خلال التمارين، مما يُحسّن من
                  كفاءة أدائك.
                </p>
                <p>
                  <span>• تحسن التمثيل الغذائي ورفع معدل الأيض:</span>
                  يرفع معدل الحرق وهرمون النمو معاً، مما يساعد على حرق الدهون
                  الزائدة وبناء كتلة عضلية صافية. كما سيُساعد جسمك على امتصاص
                  العناصر الغذائية من باقي الأطعمة واستغلالها بشكل أفضل. ومن بين
                  تلك العناصر البروتين ليعالج مقاومة امتصاص البروتين والاستفادة
                  حتى بالكميات الضئيلة منه.
                </p>
              </div>
            </div>

            <div className="paragraph">
              <div className="paragraph-content">
                <h3>فوائد دبس منتور المؤثرة على الأداء الرياضي</h3>
                <h5>داخل التمرين:</h5>
                <p>o تحسين عمليات الانقباض العضلي وزيادة القوة.</p>
                <p>
                  o تعزيز الطاقة والنشاط والتحمل مما يعني القدرة على التمرين
                  لفترات أطول.
                </p>
                <p>
                  o تحسين الانبساط العضلي والراحة خلال التمرين لاسترجاع القدرة
                  على استكمال التمرين.
                </p>
                <p>
                  o تعزيز قوة الجهاز العصبي والنواقل العصبية لتحسين الأداء
                  العام.
                </p>
                <h5>خارج التمرين:</h5>
                <p>
                  o تحسين عمليات التمثيل الغذائي للاستفادة من كامل العناصر
                  الغذائية من باقي الأطعمة المتناولة خلال اليوم.
                </p>
                <p>
                  o تحفيز انتاج التستوستيرون لدي الرجال وخفض نسب الاستروجين
                  الزائدة لدب الجنسين.
                </p>
                <p>o تحسين جودة النوم للاستفادة المثلي من ركن للاستشفاء.</p>
                <p>
                  o سد أي ثغرة بنظامك الغذائي من شأنها التأثير على استمرارك
                  ونجاحك لاستكمال ركن التغذية.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={WorkoutMolasses} alt="about-section" />
              </div>
            </div>

            <div className="paragraph left">
            <div className="paragraph-img">
                <img src={Fitness} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>
                  المزايا التراكمية لدبس منتور – رحلة التحول لنسختك الخارقة
                </h3>
                <p>
                  <span>
                    &#10003; المستوى الأول: تحالف قوي لإشعال شرارة التغيير:
                  </span>
                  تحالف قوي لإشعال شرارة التغيير: يُعدّل دبس منتور عملية التمثيل
                  الغذائي لديك ليقلل امتصاصه للسعرات من الدهون ورفع ملحوظ بمعدل
                  الحرق حتى الغير مرتبط بأي نشاط ليذيب الدهون العنيدة. كما ستشعر
                  بزيادة الطاقة والنشاط في كل حركة تقوم بها.
                </p>
                <p>
                  <span>&#10003; المستوى الثاني:</span>
                  نتائج ملحوظة تؤكد أنك بالمسار الصحيح: يمنحك دبس منتور الوقود
                  الأمثل لبناء عضلاتٍ قويةٍ خاليةٍ من الدهون. ستشاهد تحسنًا
                  ملحوظًا في قوتك وتحملك، لتتخطى حدودك وتحقق أرقامًا قياسيةً
                  جديدة في التمارين الرياضية نتيجة لفوائد المستوى الأول مجتمعة.
                </p>
                <p>
                  <span>&#10003; المستوى الثالث:</span>
                  الوصول إلى ذروة التحول: وفي تلك المرحلة، ستكون قد نجحت في
                  تحويل كامل طبيعة جسمك إلى جسم خارق، مرحلةٍ لا تحتاج فيها إلى
                  حميات غذائية قاسية لتحافظ على رشاقتك.
                </p>
                <p>
                  ولتبسيط الأمر فيمكن تشبيه المستوي الأول بقاعدة الهرم التي
                  تتكون من الفوائد سريعة التأثير والتي تتحقق بأول أسبوعين، ويظل
                  تراكم الفوائد يؤدي لفوائد أعلي وأقوي تأثيرا الي ان نصل لقمة
                  الهرم عند نجاح رحلة التحول، لتنظر حينها للهرم بصورة أشمل لتجده
                  مكون من اتحاد وتراكم عدة طبقات ومستويات كل منهم يتكون من عدة
                  فوائد ناتجة لم تكن لتتحقق قبل اكتمال فوائد الطبقة الأسفل منها،
                  وتلك هي فائدة ان تعلم أسباب مشاكلك لتعرف كيفية حلها من جذورها.
                </p>
                <p>
                  مع دبس منتور، ستصل إلى قمة لياقتك البدنية بشكلٍ طبيعيٍّ وتتمكن
                  من الحفاظ عليها طوال العام. جسدك سيصبح آلة حرقٍ فعالةٍ حتى
                  أثناء النوم، لتستيقظ دومًا بشعورٍ رائعٍ بالطاقة والحيوية.
                </p>
                <p className="gold">
                  مع استمرارك على دبس منتور، ستمتلك جسداً يحسدك الجميع عليه،
                  لتصبح قدوة لكل من يراك
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section seven */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-content">
                <h3>جمال وتألق بدون أي مواد كيميائية</h3>
                <p>
                هل تحلم ببشرةٍ نضرةٍ وشعرٍ كثيفٍ وقويٍّ؟ هل تساءلت يوما ما سر شباب وحيوية نجوم هوليوود حتى عند وصولهم لأعمار كبيرة!
                </p>
                <p className="gold">
                دبس منتور، مفتاح التجديد الخلوي، مصدر جمالٍ دائمٍ، وحياةٌ خالية من علامات الشيخوخة!
                </p>
                <p>
                يُحارب علامات الشيخوخة من جذورها، ويُضفي على بشرتك وشعرك نضارةً وحيويةً لا مثيل لها. فكما تعلم الآن، ان الأساس هو الصحة والحيوية والشباب، وأي مشاكل صحية هي الاستثناء والانحراف عن تلك الحالة الطبيعية، وأيضا علمت أنه بمعالجة سبب الانحراف، ترجع فورا الي حالتك المثالية، فقط بتلك البساطة.
                </p>
                <h5>اكتشف قوة الطبيعة في أبهى تجلياتها:</h5>
                <p>
                <span>• مكافحة الشيخوخة:</span>
                 غنيٌّ بمضادات الأكسدة، يُحارب دبس منتور الجذور الحرة ويُحسّن من مرونة البشرة ويُقلّل من ظهور التجاعيد والخطوط الدقيقة. بل يُساعد على شدّ البشرة، وتقليل التجاعيد، وتنعيم الخطوط الدقيقة، ليحافظ على مظهرك شابًا ونضراً.
                </p>
                <p>
                <span>•	جمال البشرة:</span>
                 يُساعد دبس منتور على ترطيب البشرة بعمق، ويُحافظ على توازنها، ليضيف الى بشرتك النضارةً والحيويةً اللازمة لتألقها.
                </p>
                <p>
                <span>•	معالجة حب الشباب:</span>
                 يُساعد دبس منتور على تنظيم إفرازات الغدد الدهنية، ويُحسّن من التهابات البشرة، ويُقلّل من ظهور حب الشباب والرؤوس السوداء.
                </p>
                <p>
                <span>•	علاج الشيب:</span>
                 يُغذّي دبس منتور بصيلات الشعر ويُعزّز نموها، ويُحارب الشيب المبكر، ويُضفي على شعرك لمعانًا وقوةً.
                </p>
                <p>
                <span>•	صحة المفاصل:</span>
                 يعمل ككولاجين طبيعي بسبب احتوائه على الإيلاستين، مما يساهم في صحة المفاصل، بالإضافة الي الخصائص المضادة للالتهابات الخاصة بمركبات البوليفينول المضادة للأكسدة ليصبح معزز لصحة المفاصل من كل جانب ليُقلّل من آلامها، ويُحسّن من حركتها.
                </p>
                <p className="gold">
                استثمرِ في جمالك وصحتك من الداخل والخارج، استثمرِ في ثقتك بنفسك
                </p>
                <h5>دبس منتور - مكمل غذائي أساسي لكل مرأة</h5>
                <p>
                تُعدّ الدورة الشهرية والحمل والولادة أحداث تتعرض كل امرأة ولكن لها العديد من الآثار السلبية على جسم المرأة وصحتها اذا لم تنتبه للسبب الجذري لتلك المشاكل، حيث يشيع استنزاف المعادن خلال هذه الأوقات، مما يؤدي الي مشاكل تبدأ بالإرهاق والاختلالات الهرمونية وعدم الراحة الي سكر الحمل وهشاشة العظام والسمنة والأنيميا المفرطة.
                </p>
                <p>
                لذلك يجب على كل امرأة ان تتجهز لتلك المعارك بسلاح شامل يفتك بتلك المشاكل من جذورها
                </p>
                <p>
                دبس منتور، أفضل مكمل غذائي للمرأة، بدءاً من تعويضها بكامل ما تفقده أثناء الدورة الشهرية أو الحمل الى التألق بمظهرها الخارجي من جمال ومرونة ونعومة البشرة وصحة الشعر والأظافر والأسنان. وذلك لاحتوائه المكثف علي:
                </p>
                <p>
                <span>الحديد:</span>
                 ضروري لإنتاج خلايا دم حمراء صحية، ومكافحة التعب الذي غالباً ما يرتبط بالحيض والذي تفقده النساء بغزارة مع الدم أثناء الدورة الشهرية.
                </p>
                <p>
                <span>المغنيسيوم:</span>
                 مهدئ طبيعي مسؤول عن الانبساط العضلي، يساعد في تخفيف مغص وتقلصات الدورة الشهرية وتعزيز النوم بشكل أفضل.
                </p>
                <p>
                <span>الكالسيوم:</span>
                 يدعم صحة العظام، وهو مهم بشكل خاص أثناء الحمل ويقاوم خطر الإصابة بهشاشة العظام سواء الناتج عن الحمل أو الناتج عن انقطاع الطمث.
                </p>
                <p className="gold">
                بالإضافة إلى دعم المعادن، قد يساعد دبس قصب منتور أيضاً:
                </p>
                <p>
                <span>تنظيم الهرمونات:</span>
                 تلعب بعض المعادن دورًا في توازن الهرمونات، مما قد يخفف من بعض مضايقات الحمل والدورة الشهرية ويقضي علي حب الشباب وتوزيع الدهون داخل الجسم وتعزيز المناعة.
                </p>
                <p>
                <span>الوقاية من سكري الحمل:</span>
                 تؤكد أبحاث منظمة الغذاء والدواء الأمريكية إلى وجود صلة بين دبس القصب وتحسين التحكم في نسبة السكر في الدم، مما قد يفيد النساء الحوامل.
                </p>
                <p className="gold">
                دبس منتور هو نهجاً طبيعياً لصحة كل امرأة وطريقة لذيذة لدعم جسمك في كل مرحلة من مراحل الحياة!
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Beauty} alt="about-section" />
              </div>
            </div>

            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={Mother} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h5>
                دبس منتور - راحة بال لكل أم ذكية
                </h5>
                <p>
                قد تبدو تربية أطفال أصحاء وكأنها معركة مستمرة، خاصة بعدما تيقنا أن أغلب مصادر طعامنا اليومي خلت من قيمتها الغذائية وتزايدت الأنواع المختلفة من الحلويات، وقد لاحظنا كثيرا تأثير ذلك بانتشار حالات السمنة والأنيميا والضعف العام بأطفالنا اليوم، كما يزداد التحدي امام كل ام عندما يتعلق الأمر بالأطفال الذين يصعب إرضاءهم في تناول الطعام. ولكن ماذا لو كانت هناك طريقة لذيذة وبسيطة لضمان حصولهم على العناصر الغذائية الأساسية التي يحتاجونها، بغض النظر عما يتناولونه باقي اليوم؟
                </p>
                <p>
                دبس منتور - السلاح السري للآباء والأمهات! مزيج قوي من الفيتامينات والمعادن الأساسية يضمن لك حصولهم علي كامل احتياجاتهم اليومية لأهم العناصر الغذائية التي يحتاجونها والتي تعمل معًا من أجل:
                </p>
                <p>
                  <span>دعم النمو الصحي:</span>
                  يوفر لهم دبس منتور كامل ما يحتاجونه لبناء عظام وعضلات قوية ونمو سليم. فالكالسيوم ضروري لبناء عظام قوية، ولكنه يحتاج إلى التوجيه والا قد يصبح ضاراً. لذا يوفر دبس منتور نسب متوازنة من الكالسيوم والمغنيسيوم والبوتاسيوم والفوسفور ليعملا معاً على تمعدن العظام وتحسين كثافتها.
                </p>
                <p>
                  <span>الوقاية من الأنيميا ونقص العناصر الغذائية:</span>
                  حتى مع اتباع نظام غذائي أقل من العادي، يساعد دبس منتور على سد الثغرات بنظامهم الغذائي وضمان حصول طفلك على العناصر الغذائية الحيوية التي يحتاجها للنمو. كما يعزز ملفه الغذائي المتوازن والغني بالفيتامينات والمعادن على ضمان امتصاصها واستغلالها بكفاءة، مما يضمن لطفلك الحصول على أقصى استفادة مما يستهلكه. كما يدعم دبس منتور الغني بالحديد إنتاج خلايا الدم الحمراء الصحية ويكافح التعب ويعزز الصحة العامة. كما يعزز النحاس الموجود في دبس منتور من امتصاص الحديد.
                </p>
                <p>
                  <span>تعزيز الوظائف الادراكية والذاكرة والتعلم ودعم الأعصاب وتعزيز الطاقة والحيوية:</span>
                  كنز ملئ بمجموعة فيتامين ب ومضادات الأكسدة والكالسيوم والبوتاسيوم والمغنيسيوم، تلك العناصر الضرورية لصحة الجهاز العصبي وانتقال الإشارات الكهربية بداخله من والي المخ بفاعلية.
                </p>
                <p className="gold">دبس منتور - أكثر من مجرد تغذية، إنه راحة البال</p>
              </div>
            </div>
          </div>
        </section>
        {/* section Eight */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={SpecialPrivilege} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>لماذا القصب المصري هو الأفضل عالمياً؟ الامتياز الخاص لدبس القصب المصري</h3>
                <p>
                يشتهر قصب السكر المصري عالمياً بجودته العالية ونكهته المميزة. ومع ذلك، هناك ميزة مهمة جداً أخرى لقصب السكر المصري غير معروفة على نطاق واسع، وهي حقيقة ان القصب المصري خالي تماما من المعادن الثقيلة.
                </p>
                <p>
                فالتربة المصرية رغم غناها بالمعادن المفيدة، إلا انها تتميز بخلوها من المعادن الثقيلة وذلك بفضل الطبيعة الجيولوجية الاستثنائية لوادي النيل.
                </p>
                <p>
                فوادي النيل هو وادٍ متجدد، يكتسب خصوبته من طمي النيل الذي يبدأ رحلته كصخر تنحته الأمطار الغزيرة بأعالي جبال وسط أفريقيا ثم يقوم نهر النيل بنقله مع التيار على طول المسافة بدءا ًمن منابعه إلى نهايته بمصر ليقوم بترسيب ذلك الطمي على ضفاف وادي النيل مما يجدد التربة الزراعية باستمرار. ومع مرور المياه عبر الطبقات المختلفة لوادي النيل والتي تعمل كمرشح وفلتر طبيعي، تترسب المعادن الثقيلة مثل الرصاص والزئبق والكادميوم ببداية الطريق. ومع طول المسافة بين مصر ومنابع النيل في أفريقيا (حوالي 6650 كيلومتراً) يتوفر الوقت الكافي لترسب معظم المعادن الثقيلة قبل وصول المياه إلى مصر.
                </p>
                <p>
                وليس هذا هو الحال في العديد من المناطق الأخرى في العالم حيث قد تكون التربة الزراعية مستنفدة أو ملوثة.
                </p>
                <p>
                فعلى سبيل المثال، يشتهر دبس القصب الذي يتم انتاجه بأمريكا الجنوبية باحتوائه على الرصاص بسبب الطبيعة الجيولوجية لتلك الأنهار الدائرية على عكس الطبيعة الطولية لنهر النيل التي تسمح بوجود منطقة مثل الأراضي المصرية بنهايته لتكون نظيفة تماما من المعادن الثقيلة، هذا بالإضافة الي التلوث الصناعي بذلك الجزء من العالم.
                </p>
                <p>
                وإذا وجهت أنظارك نحو مناطق آسيا التي أيضا تقوم بإنتاج الدبس. ستجدها تستخدم أعنف الممارسات الزراعية سلبية، مثل الاستخدام المكثف للمبيدات الحشرية والأسمدة الكيماوية وتفشي التعديل الوراثي للمحاصيل.
                </p>
                <p>
                ومن أهم النقاط، هو مدي قدم تلك الزراعة بمصر، فعلي عكس الشائع ان أول من بدأ زراعة القصب كانت الهند، فان مصر تعرف تلك الزراعة وتقدرها منذ آلاف السنين، ومن أهم الشواهد علي ذلك هو المعتقد القديم بالميثولوجيا المصرية القديمة التي ظنت ان جنة الآخرة هي حقول من القصب تسمي ب "آرو". ونتيجة لمعرفة المصريين بتلك الزراعة والصناعة من قبل عصر انتاج السكر الأبيض، اشتهرت الهند تلك الشهرة الكاذبة نتيجة لان الهنود هم أول من زرع القصب بغرض انتاج السكر، بينما ظلت مصر الدولة الوحيدة التي تمتلك خطوط انتاج خاصة بالدبس الفاخر تختلف كليا عن تلك المخصصة لإنتاج السكر،
                </p>
                <p>
                كما يتميز دبس القصب المصري بمحتواه الأغنى بالمغذيات عن باقي أنواع الدبس الأخرى، فتركيز الحديد به أعلي من 10 مجم لكل 100جرام. في حين يحتوي دبس القصب سواء الاسيوي أو البرازيلي فقط 4 مجم من الحديد لكل 100جرام).
                </p>
                <p>
                لذلك، يُعد دبس قصب السكر المصري الخيار الامثل للأشخاص الذين يبحثون عن منتجات طبيعية خالية من المعادن الثقيلة والمواد الكيميائية. وتفيض بالمعادن المفيدة مثل الحديد والكالسيوم والمغنيسيوم، للاستمتاع بفوائدها العديدة دون أي قلق.
                </p>
                <h5>
                دبس منتور - الحل الأمثل لنمط حياة صحي!
                </h5>
                <p>
                يتضح لنا الان لماذا يعتبر دبس منتور تلك النسخة الفريدة التي تنتجها آدم للمواد الغذائية وحدها هي الخيار الأمثل بامتياز لعصرنا الحديث سريع الايقاع، وكيف يمكن استخدامه بطرق لا نهائية بقدرته الاستثنائية علي توفير العديد من الفوائد الصحية بملعقة واحدة وبتركيبة مثالية تناسب نمط الحياة الحديث والمليء بالتحديات بتركيزه الفائق للعناصر الغذائية وتنوع استخداماته وقلة السعرات بداخله. فله خصائص فريدة تساعد على تنظيف الرئة من آثار التدخين، ودعم الكبد ضد آثار الكحوليات، وتنظيف الجسم من السموم. كما يعزز قدرة الجسم على امتصاص مختلف العناصر الغذائية من الأطعمة الأخرى واستغلالها جيدًا.
                </p>
                <p>
                وهذا يعني أنك تحصل على أقصى استفادة من نظامك الغذائي، حتى وان لم تتبع نظامًا غذائيًا مثاليًا بالإضافة الي قدراته الجبارة في إدارة الوزن وتعزيز مستويات الطاقة والحيوية والتحمل لأسلوب حياة نشط، وتحسين ورفع معدل الحرق، وتحفيز انتاج التستوستيرون عند الرجال وتقليل نسب الاستروجين الضارة عند النساء وتعزيز الصحة الإنجابية لكل منهم، ودعم جهاز المناعة والكبد وصحة القلب والأوعية الدموية، وتنظيم ضغط الدم وتعزيز صخة الشرايين والأوردة.
                </p>
                <p>
                وهذا يعني أنك تحصل على أقصى استفادة من نظامك الغذائي، حتى وان لم تتبع نظامًا غذائيًا مثاليًا بالإضافة الي قدراته الجبارة في إدارة الوزن وتعزيز مستويات الطاقة والحيوية والتحمل لأسلوب حياة نشط، وتحسين ورفع معدل الحرق، وتحفيز انتاج التستوستيرون عند الرجال وتقليل نسب الاستروجين الضارة عند النساء وتعزيز الصحة الإنجابية لكل منهم، ودعم جهاز المناعة والكبد وصحة القلب والأوعية الدموية، وتنظيم ضغط الدم وتعزيز صخة الشرايين والأوردة.
                </p>
                <p className="gold">
                دبس منتور – غذاؤك دواؤك
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section nine */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={Mix1} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>الثنائي الخارق –  أغني طعام بالكون</h3>
                <p>
                عندما يجتمع دبس القصب الغني والحلو مع الطحينة ثاني أقوي سوبر فود  بعد الدبس، تكون النتيجة مزيجاً لا يقاوم من النكهات والقوام! ليكوّن ثنائي رائع من الطعم والقيمة الغذائية بتلك الكريمة اللذيذة والتي تجمع بين الطعم المعدني الحلو والعميق لدبس منتور وطعم المكسرات لتحقيق الانسجام المثالي بين الطعم والقيمة الغذائية.
                </p>
                <p>
                عندما يجتمع دبس القصب الغني والحلو مع الطحينة ثاني أقوي سوبر فود  بعد الدبس، تكون النتيجة مزيجاً لا يقاوم من النكهات والقوام! ليكوّن ثنائي رائع من الطعم والقيمة الغذائية بتلك الكريمة اللذيذة والتي تجمع بين الطعم المعدني الحلو والعميق لدبس منتور وطعم المكسرات لتحقيق الانسجام المثالي بين الطعم والقيمة الغذائية.
                </p>
                <p>
                يوفر هذا المزيج الرائع من دبس منتور وطحينة سمسم منتور من آدم مجموعة شاملة وكاملة من العناصر الغذائية الكبرى والصغري معا، بما في ذلك البروتين والكربوهيدرات المعقدة والألياف والدهون الصحية والفيتامينات والمعادن ومضادات الأكسدة وأهم الأحماض الأمينية، وكأنك لا تحتاج شيء آخر بجانبهما.
                </p>
                <p>
                فقط ملعقتان 20 جم من دبس منتور وملعقتان 15 جم من طحينة منتور ستمنحك 92% من الحديد، و23% من الكالسيوم، و44% من المغنيسيوم، و30% من البوتاسيوم، و30% من الفوسفور، و81% من النحاس، و21% من الزنك، و50% من الكروم، و20% من السيلينيوم، و63% من المنغنيز الذي يحتاجه جسمك يومياً بصورة طبيعية بكل سهولة وسرعة! وأكثر من ذلك بكثير!
                </p>
                <p>
                حيث يوفر هذا الخليط المذهل أيضًا جميع العناصر الغذائية الأساسية الأخرى، مما يمنحك دفعة هائلة من البوليفينول والليوسين والأوميغا 3 والثيامين (B1) والريبوفلافين (B2) والنياسين (B3) وفيتامين B5 وفيتامين B6 والكولين والثريونين والتريبتوفان والكاروتين والليسين والليوسين والأرجينينين والمزيد! 
                </p>
              </div>
            </div>

            <div className="paragraph">
              <div className="paragraph-content">
              <img className="img-full-width" src={Mix2} alt="mix-table"/>
                <p>
                والجزء الأفضل هو أنك قادر ان توفر كل هذه العناصر الغذائية المذهلة بمحتوي سعرات أقل من 11% من سعرات الثبات اليومي بوجبة خفيفة ألذ من الشوكولاتة. وجبة مؤشرها الجلايسيمي لا يُذكر، حتى أنه يناسب متبعي حميات الكيتو بأمان!
                </p>
                <p className="gold">
                “ Be Stronger - Be Smarter “
                </p>
                <img className="img-full-width" src={AdamGold} alt="mix-table"/>
                <p className="gold">
                Adam – The Health engineers
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default MolassesAr;
