import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../utilities/Navbar";
import FooterAr from "../../utilities/FooterAr";
import MentorSesame from "../../images/imgs/mentor-sesame.webp";
import TahiniImg from "../../images/imgs/tahini-3.webp";
import GreatBenefits from "../../images/imgs/great-benefits.webp";
import DontWait from "../../images/imgs/don't-wait.webp";
import Sesame from "../../images/imgs/sesame.webp";
import Nile from "../../images/imgs/nile.webp";
import Doctor from "../../images/imgs/doctor.webp";
import Tahini6 from "../../images/imgs/tahini-6.webp";
import Tahini7 from "../../images/imgs/tahini-7.webp";
import SesameOil from "../../images/imgs/sesame-oil.webp";
import Key from "../../images/imgs/key.webp";
import OilHair from "../../images/imgs/hair.webp";
import Salad from "../../images/imgs/salad.webp";
import Waffles from "../../images/imgs/waffles.webp";
import Smoothies from "../../images/imgs/smoothies.webp";
import Diet from "../../images/imgs/diet.webp";
import AdamGold from "../../images/imgs/adam-gold.webp";

import { Helmet } from "react-helmet";

const TahiniAr = () => {
  return (
    <>
      <Navbar />
      <div className="about-page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Adam Superfoods | طحينة أدم</title>
        </Helmet>
        <div className="about-container">
          <div className="about-intro">
            <div className="container">
              <div className="intro-info ar">
                <h3>طحينة أدم</h3>
                <p>
                  تحفة طهي غذائية مصنوعة بعناية من أجود أنواع
                  <br />
                  .حبوب السمسم
                </p>
                <div className="work-with">
                  <Link to="">أكتشف الأن</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section one */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>طحينة السمسم من آدم – صيدلية كاملة</h3>
                <p>
                  هل تعاني من ارتفاع ضغط الدم أو انتشار الالتهابات بمختلف أنحاء
                  جسمك مثل القولون أو المفاصل. هل تعاني ضعف النظر أو هشاشة
                  العظام أو الأنيميا؟ هل تستمر على أدوية أمراض مزمنة لا تجدي
                  نفعا في القضاء على المرض وتجبر ان تستمر عليها طوال حياتك؟
                </p>
                <p>
                  لست وحدك! فأغلب البشر يعانون من تلك المشاكل نتيجة عدم النظر
                  لجسم الانسان كحلقة كاملة. فعلي مدي قرن، اعتمد الأطباء على وصف
                  أدوية لمرضاهم لا تُعالج سوى أعراض المرض، دون النظر إلى أسبابه
                  العميقة.
                </p>
                <p>
                  ولكن بعد ان تم كشف الغطاء عن السبب الحقيقي للأمراض والمشاكل
                  الصحية المختلفة المتمثل بافتقار الجسم لبعض العناصر الغذائية
                  الهامة وعادات الأكل السيئة وسعي كل منّا لإصلاح تلك المشكلات
                  عبر اتباع الحميات الغذائية الصحية، وجدنا المفاجئة، فاصطدمنا
                  بأكبر عائق في طريق الحل وهو انتشار التلاعب البشري بالصفات
                  الوراثية بمصادر طعامنا بهدف زيادة الإنتاج النباتي والحيواني
                  والذي أفقد طعامنا قيمته، فأصبح من المستحيل الحصول على كامل
                  احتياجنا اليومي من العناصر الغذائية من الطعام وحده ضربًا من
                  الخيال. فتحولت الألبان من أشهر مصدر الكالسيوم المتوفرة في
                  متناول الجميع، الي أكثر المواد المستنزفة لصحة العظام لسحبه
                  للكالسيوم من العظام ليعادل حمضيته التي زادت نتيجة التلاعب
                  الجيني بالأبقار.
                </p>
                <h3>فما هو الحل إذن؟</h3>
                <p>
                  هناك عدد قليل من الأطعمة التي تحتوي على الكثير من العناصر
                  الغذائية بصورة متعددة وبتركيزات عالية، والتي يُطلق عليه سوبر
                  فود. ومن أقوي تلك الأطعمة النادرة هي الطحينة مثل دبس القصب
                  تماماً. فالأرض ما زالت تثمر بالأطعمة التي حافظت على حالتها
                  الخام وتنتظر من يكشف عنها الغطاء ويتعامل معها بكل بساطة ومهارة
                  ليعيد اكتشافها وتقديمها كحل مثال لكل عوائق وتحديات الحياة
                  الحديث.
                </p>
                <p>
                  جميعنا بعرف طحينة السمسم جيدا ونحبها ونستخدمها بالعديد من
                  الوصفات. ولكن هل تعلم مدي أهمية الاختيار الصائب للطحينة بدءا
                  ًمن طريقة صنعها ونقائها وجودتها ونوع السمسم المستخدم للتمتع
                  بفوائدها وعدم التعرض لأضرار المواد المعتاد اضافتها بواسطة
                  التجار عديمي الضمير مثل الدقيق والخبز المفروم وزيت النخيل
                  والمواد الحافظة ومثبتات القوام الاصطناعية وليسيثين الصويا
                  وغيرها من المواد الضارة.
                </p>
                <p>
                  الطحينة منتج رائع! تنتج عن طحن بذور السمسم الي ان تتحول إلى
                  معجون كريمي ذو تركيز عالي بالعناصر الغذائية والدهون الصحية
                  ومنخفض السعرات وسهل الاستخدام.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={TahiniImg} alt="about-section" />
              </div>
            </div>

            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Sesame} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                  يعتبر السمسم من أقدم البذور الزيتية التي استخدمها الأجداد.
                  ويعود تاريخ الاكتشافات الأولى حول مكانتها في النظام الغذائي
                  إلى ما لا يقل عن 5,500 سنة مضت! وقد اشتهرت بالمناطق التي كان
                  يُزرع بها بمكانة خاصة واستخدمت بانتظام في وصفات الطعام منذ
                  العصور القديمة وحتى اليوم، مناطق مثل بلدان الشرق الأوسط وشرق
                  أفريقيا. مصر، وفلسطين، وسوريا، ولبنان، والعراق، وغيرها الكثير!
                  حتى في اليونان القديمة، هناك العديد من الإشارات إلى استخدامه
                  في التغذية بطرق مختلفة، وبينما استخدمه أبقراط وجالينوس في
                  الطب. أا بالبلدان الآسيوية، أشتهر زيت السمسم بآسيا على نطاق
                  واسع بفوائده المذهلة للجسم والبشرة!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section Two */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>لماذا طحينة السمسم من آدم؟</h3>
                <p>
                  <span>غني بالعناصر الغذائية:</span>
                  مما يجعلها معززاً شاملاً للصحة، كما تعتبر طحينة السمسم أفضل
                  مصدر للزنك والكالسيوم والنحاس والحديد بصورة تضمن وصول
                  الكالسيوم بنجاح للعظام والأسنان والأظافر لتعزيز صحتهما
                  بفاعلية.
                </p>
                <p>
                  <span>مطحونة على البارد بمهارة استثنائية:</span>
                  تُصنع من خلال عملية طحن لطيفة وباردة باستخدام الحجر الي يحافظ
                  على جميع الزيوت الطبيعية والعناصر الغذائية والنكهات الموجودة
                  في بذور السمسم دون التعرض للحرارة أو المواد الكيميائية.
                </p>
                <p>
                  <span>نقية بنسبة 100%:</span>
                  خالية من أي مثبتات أو مواد حافظة أو أي إضافات أخرى من أي نوع،
                  مما يضمن لك الحصول على أنقى صورة بالوجود للطحينة مع زيت السمسم
                  الخاص بها دون تبديله كما تفعل العلامات التجارية الأخرى، حتى
                  تتمكن من الاستمتاع بزيت السمسم النقي في نفس العبوة!
                </p>
                <p>
                  <span>خالية من الجلوتين:</span>
                  للاستمتاع بمذاقها اللذيذ وفوائدها الغذائية دون قلق.
                </p>
                <p>
                  <span>مضاد قوي للشيخوخة:</span>
                  لتعالج الخمول والكسل والسمنة ومقاومة الأنسولين واحتباس السوائل
                  تحت الجلد وانتفاخ الوجه وضغط الدم المرتفع والسكري واختلال
                  التوازن الهرموني وأمراض القلب وهشاشة العظام والأمراض الجلدية
                  والأورام السرطانية.
                </p>
                <p>
                  <span>مناسبة لجميع الحميات:</span>
                  حتى لمن يتبعون أي نوع من أنواع الحميات الغذائية، بما في ذلك
                  الحميات الكيتونية والنباتية. بل تعتبر أهم مادة لهم بنظامهم
                  الغذائي.
                </p>
                <p>
                  <span>متوافقة تماماً مع فلسفة آدم:</span>
                  فعّال وقوية التأثير – فائقة التركيز بالعناصر الغذائية المختلفة
                  – لها فوائد علاجية ووقائية متعددة وقوية - آمنة للاستخدام -
                  شاملة لأهداف المختلفة وسهلة التخصيص لكل هدف – لذيذة ومتعددة
                  الاستخدامات.
                </p>
                <p>
                  <span>لذيذة ومتعددة الاستخدامات:</span>
                  طحينة السمسم لها نكهة لذيذة ومميزة ويمكن استخدامها في مجموعة
                  متنوعة من الأطباق.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={TahiniImg} alt="about-section" />
              </div>
            </div>
            <div className="paragraph">
              <div className="cards-container">
                <div className="card">
                  <img src={Salad} alt="salad" />
                  <h4>سلطة الطحينة</h4>
                  <p></p>
                </div>

                <div className="card">
                  <img src={Waffles} alt="" />
                  <h4>الوافل والكيكة</h4>
                  <br />
                  <p></p>
                </div>

                <div className="card">
                  <img src={Smoothies} alt="" />
                  <h4>العصائر</h4>
                  <p></p>
                </div>

                <div className="card">
                  <img src={Diet} alt="" />
                  <h4>نظام غذائي</h4>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section Three */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>طحينة منتور</h3>
                <p>
                  تحفة فنية في عالم الطهي والتغذية، مصنوعة بعناية من أجود بذور
                  السمسم. تمثل تلك الكريمة الرائعة الانسجام المثالي بين المذاق
                  والقيمة الغذائية والتي تشكل ثنائي رهيب بالطعم والقيم الغذائية
                  مع دبس منتور. فهي بمفردها مصدر مركز للبروتين والمعادن والألياف
                  والدهون الصحية. وعند اجتماعها بدبس القصب، ينشأ مزيج لا يقاوم
                  من النكهات والقوام. ليضاعف هذا الثنائي المميز من تركيز وتنوع
                  العناصر الغذائية المختلفة ليغطي كافة احتياجات الجسم اليومية من
                  جميع العناصر الحيوية بالكامل دون الحاجة إلى تناول أي حبوب أو
                  مكملات غذائية أخرى، حيث يوفر هذا المزيج الرائع من دبس وطحينة
                  منتور من آدم مجموعة شاملة وكاملة من العناصر الغذائية الكبرى
                  والصغرى معا، بما في ذلك البروتين والكربوهيدرات المعقدة
                  والألياف والدهون الصحية والفيتامينات والمعادن ومضادات الأكسدة
                  وأهم الأحماض الأمينية، وكأنك لا تحتاج شيء آخر بجانبهما.
                </p>
                <p>
                  ففقط بملعقتين 20 جم من دبس منتور وملعقتين 15 جم من طحينة منتور
                  تمنحك 92% من الحديد، و23% من الكالسيوم، و44% من المغنيسيوم،
                  و30% من البوتاسيوم، و30% من الفوسفور، و81% من النحاس، و21% من
                  الزنك، و50% من الكروم، و20% من السيلينيوم، و63% من المنغنيز
                  الذي يحتاجه جسمك يومياً بصورة طبيعية بكل سهولة وسرعة! وأكثر من
                  ذلك بكثير!
                </p>
                <p>
                  حيث يوفر هذا الخليط المذهل أيضًا جميع العناصر الغذائية
                  الأساسية الأخرى، مما يمنحك دفعة هائلة من البوليفينول والليوسين
                  والأوميغا 3 والثيامين (B1) والريبوفلافين (B2) والنياسين (B3)
                  وفيتامين B5 وفيتامين B6 والكولين والثريونين والتريبتوفان
                  والكاروتين والليسين والليوسين والأرجينين والمزيد!
                </p>
              </div>
              <div className="paragraph-img">
                <img src={MentorSesame} alt="about-section" />
              </div>
            </div>
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Nile} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                  والمذهل هو أنك قادر ان توفر كل هذه العناصر الغذائية المذهلة
                  بمحتوي سعرات أقل من 11% من سعرات الثبات اليومي بوجبة خفيفة ألذ
                  من الشوكولاتة. وجبة مؤشرها الجلايسيمي لا يُذكر، حتى أنه يناسب
                  متبعي حميات الكيتو بأمان!
                </p>
                <p>
                  لنتيقن أخيرا أن هذا المزيج هو الحل الأمثل ليضمن لكل رياضي وكل
                  أم راحة باليهما نحو تلبية احتياجات اجسادهما وأجساد أطفالهما من
                  العناصر الغذائية المهمة بغض النظر عن باقي الأطعمة المتناولة
                  على مدار اليوم دون أي أضرار من الاستخدام لفترة طويلة على عكس
                  المكملات الصيدلانية المحضرة معمليا. مما يجعل من هذا المزيج
                  بمثابة صيدلية كاملة ممتلئة بالمكملات الغذائية المركزة فقط
                  بملعقتين من خليطهما.
                </p>
                <h5>طحينة منتور هي الحل لكل من:</h5>
                <p>
                  - يبحث عن أسلوب حياة صحي ويريد الوصول لجسم مثالي بشكل أسرع
                  وأكثر صحة دون حرمان.
                </p>
                <p>- الرياضيين بجميع أنواعهم وبمختلف الأهداف.</p>
                <p>
                  - الأشخاص الذين يرغبون في تحسين صحتهم وحيويتهم بشكل عام لتحقيق
                  أهداف مختلفة، سواء كان ذلك بناء كتلة عضلية خالية من الدهون أو
                  فقدان كتلة الدهون دون التأثير سلباً على صحتهم وعملية الأيض في
                  المستقبل.
                </p>
                <p>
                  - مرضي السكري والضغط لوقايتهم مما يتعرضون له من التهابات
                  بالأوعية الدموية والأعصاب وهشاشة العظام وضعف النظر.
                </p>
                <p>
                  - أي فتاة أو امرأة بشكل عام والحوامل وحديثي الولادة والحائض
                  بشكل خاص.
                </p>
                <p>
                  - أي أم تريد راحة البال والاطمئنان على صحة أطفالها، وتضمن
                  توفيرها لكامل العناصر الغذائية الأساسية التي يحتاجونها يوميا
                  للنمو والتطور.
                </p>
                <p className="gold">
                  اكتشف بنفسك كيف تعيش حياة أفضل والاستمتاع بجسم صحي يعمل بأعلى
                  كفاءة!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section Four */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>:فوائد طحينة منتور العظيمة للصحة العامة</h3>
                <h5>دعم القلب والأوعية الدموية</h5>
                <p>
                  تعمل الدهون الأحادية والمتعددة غير المشبعة في الطحينة على
                  تعزيز صحة القلب عن طريق خفض الكوليسترول الضار LDL مع رفع
                  مستويات الكوليسترول الحميد النافع HDL بالإضافة الي دور أوميجا
                  3 في تقليل الالتهابات التي تؤدي الي الجلطات. كما يعمل محتواه
                  من المغنيسيوم والبوتاسيوم على دعم صحة الشرايين ومرونتها مما
                  يقوم بخفض ضغط الدم المرتفع وتنظيم ضربات القلب.
                </p>
                <h5>تنظيم سكر الدم والهرمونات</h5>
                <p>
                  تساعد العناصر الغذائية الفريدة الموجودة في الطحينة على تنظيم
                  نسبة السكر في الدم وتحسين إنتاج الهرمونات، كما يعمل محتواها من
                  الألياف الي بطء وصول أي سكريات الي الدم مما يقلل من خطر
                  الإصابة باضطرابات التمثيل الغذائي مثل داء السكري والسمنة.
                </p>
                <h5>عظام وأسنان أقوي</h5>
                <p>
                  تدعم الطحينة الغنية بالكالسيوم والماغنيسيوم والمعادن الأخرى
                  قوة العظام وتزيد من كثافة العظام، مما يقي من هشاشة العظام.
                </p>
                <h5>مقوٍّ للخصوبة والصحة الانجابية</h5>
                <p>
                  تحتوي الطحينة على جميع المركبات التي تعزز الخصوبة والصحة
                  الجنسية لدى الرجال والنساء على حد سواء، وتدعم الصحة الإنجابية
                  وإنتاج الحيوانات المنوية بشكل طبيعي.
                </p>
                <h5>تقوية الجهاز المناعي وعلاج جميع الالتهابات</h5>
                <p>
                  تركيزها العالي بالزنك ومضادات الأكسدة وأوميجا 3 يعمل علي تعزيز
                  قوة الجهاز المناعي ودعم الخلايا التائية وتقليل الالتهابات
                  بالإضافة الي قيام مضادات الأكسدة الموجودة في الطحينة بحماية
                  الخلايا من الإجهاد التأكسدي والالتهابات المزمنة، مما يشكل نظام
                  مناعي خارق.
                </p>
                <h5>دعم صحة الجهاز الهضمي والأمعاء</h5>
                <p>
                  تعمل الألياف والدهون المفيدة في الطحينة على تعزيز صحة
                  ميكروبيوم الأمعاء وتحسين عملية الهضم وامتصاص العناصر الغذائية
                  كما تعمل كملين طبيعي بلا أي آثار جانبية لاحتوائها على
                  المغنيسيوم المسؤول عن حركة الأمعاء السلسة.
                </p>
                <h5>تعزيز لجمال وصحة البشرة والشعر والأسنان</h5>
                <p>
                  تعمل الفيتامينات والمعادن والدهون الصحية الموجودة في الطحينة
                  على تغذية البشرة والشعر والأظافر، مما يعزز مظهر الشباب
                  والإشراق.
                </p>
                <h5>تحسين المزاج وجودة النوم والقضاء علي الاكتئاب</h5>
                <p>
                  تعمل طحينة منتور علي تحسين المزاج بأقوى طريقة عرفها الانسان
                  وذلك لاحتوائها علي التريبتوفان الذي يتحول داخل الجسم الي
                  سيروتونين أو ما يعرف بهرمون السعادة، بالإضافة الي احتوائها علي
                  مجموعة فيتامين ب التي تعزز تأثير السيروتونين، هذا بجانب قدرتها
                  علي تنظيم التوازن الهرموني مما يقضي علي القلق والتوتر ويحسن
                  المزاج وجودة النوم بحل طبيعي آمن أقوي وأكثر فاعلية من أي دواء.
                </p>
                <h5>تحسين الأداء العضلات والاشارات العصبية</h5>
                <p>
                  تحتوي طحينة منتور علي تركيز عالي جداً لمجموعة فيتامينات ب
                  والكهارل المختلفة مثل الكالسيوم والمغنيسيوم والبوتاسيوم التي
                  تعمل على تحسين ودعم الوظائف العصبية والشوارد الأساسية لتحسين
                  الوظائف العصبية والحركة الانقباضية والانبساطية للعضلات.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={GreatBenefits} alt="about-section" />
              </div>
            </div>

            <div className="paragraph">
              <div className="paragraph-img">
                <img src={DontWait} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>طحينة منتور مكمل غذائي شامل استثنائي</h3>
                <p>
                  تحتوي طحينة منتور على أكثر من 35 معدن وفيتامين وحمض أميني
                  بالإضافة الي الأحماض الدهنية الصحية! إنها مصدر مثالي لأغلب
                  العناصر الغذائية التي يحتاجها جسمك دون الحاجة إلى تناول أي
                  حبوب أو مكملات غذائية أخرى، كما يعفيك من الآثار الضارة الناتجة
                  عن استخدام حبوب المكملات الغذائية المختلفة على المدى الطويل
                  ويضمن لك ان جسمك به كامل ما يحتاجه من عناصر مهمة طوال الوقت،
                  وهذا ما لا يقدر عليه أي مكمل آخر.
                </p>
                <p>
                  <span>بديل طبيعي أفضل للمكملات الغذائية:</span>
                  على عكس الحبوب والمكملات الدوائية التي قد تكون صعبة على المعدة
                  وتسبب آثاراً جانبية طويلة الأمد والتي لا تمدك سوي بعنصر واحد
                  أو علي أقصي تقدير مجموعة من العناصر قد يمتصها جسمك وقد تكون
                  حملا عليه وعلي كليتك، فإن طحينة منتور تمد جسمك بجميع العناصر
                  الغذائية التي يحتاجها دون أي مخاطر من الاستخدام اليومي لفترات
                  طويلة، كما تضمن الامتصاص الأمثل لما تحتويه من فائدة.
                </p>
                <p>
                  <span>الامتصاص:</span>
                  الطحينة هي مصدر طبيعي للعناصر الغذائية التي يتم امتصاصها بشكل
                  أفضل من المكملات الغذائية التي يصعب على الجسم امتصاصها، كما
                  يساعد تنوع وتعدد العناصر الغذائية المختلفة بالطحينة علي تحسين
                  الامتصاص، فعلي سبيل المثال وليس الحصر، لا تحتوي الطحينة علي
                  تركيز عالي فقط من الحديد عالي الجودة، بل تحتوي أيض علي أكثر
                  عنصرين يساعدان الجسم علي امتصاص الحديد وهما النحاس وفيتامين C،
                  كما تحتوي علي مضادات الأكسدة لحماية الخلايا من الاجهاد
                  التأكسدي الذي ينتج عن التعرض للعدوي او زيادة الحديد. مما يضمن
                  الامتصاص والاستغلال الأمثل لما تحتويه من قبل الجسم دون أي
                  مخاطر من تأثيرات لا توجد سوي بالأدوية الصيدلانية والمواد
                  الكيميائية مثل الجرعة الزائدة أو الآثار الجانبية. هنا لا توجد
                  سوي فوائد جانبية.
                </p>
                <p>
                  <span>تعزيز صحة الجهاز الهضمي والتمثيل الغذائي:</span>
                  عندما تساعد طحينة منتور على تعزيز صحة جهازك الهضمي وتحسين قدرة
                  جسمك على امتصاص العناصر الغذائية من الأطعمة الأخرى والاستفادة
                  منها بفعالية. يضمن لك ان تحصل على أقصى استفادة من نظامك
                  الغذائي، حتى لو لم يكن مثاليًا.
                </p>
                <p>
                  <span>طبيعية 100%:</span>
                  طحينة منتور هي منتج طبيعي خالٍ من المواد الحافظة وآمن
                  للاستخدام على المدى الطويل ولكافة الفئات العمرية
                </p>
                <p className="gold">
                  طحينة منتور هي أفضل مصدر على الإطلاق للكالسيوم والزنك والحديد
                  والنحاس!
                </p>
              </div>
            </div>

            <div className="paragraph right">
              <div className="paragraph-content">
                <p>
                  يضمن المزيج المثالي من العناصر الغذائية الموجودة في طحينة
                  مينتور أن يشعر كل رياضي وكل أم بالثقة بأن أجسامهم وأجسام
                  أطفالهم تلبي احتياجاتهم الغذائية اليومية، بغض النظر عن بقية
                  الأطعمة التي يتناولونها على مدار اليوم. حيث ان تنوع العناصر
                  يساعد الجسم على امتصاصها بشكل أفضل.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Doctor} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section five */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>طحينة منتور لدعم اللياقة البدنية والأداء الرياضي</h3>
                <p>
                  هل تعاني من صعوبة في التقدم نحو هدفك وتحقيق نتائج مرضية من
                  التدريبات الخاصة بك؟ هل تعاني من الخمول أو الإرهاق؟ هل تشعر
                  بالإحباط من صعوبة التقدم أو بطء التعافي؟ تضغط على نفسك بشدة في
                  صالة الألعاب الرياضية، لكنك لا تؤدي بكامل إمكانياتك تشعر بألم
                  في عضلاتك، وانخفاض متكرر بمستوى طاقتك، تشعر بتشويش وضعف
                  التركيز.
                </p>
                <p>
                  ولكن لا تيأس، فالعيب ليس بك وانما بالعقلية التي ننظر بها
                  للنظام الغذائي وتركيز المدربين الأعمى علي كمية السعرات
                  والبروتين والكربوهيدرات دون مراعاة العناصر الحاسمة التي تُحدد
                  فعالية استغلال الجسم لتلك العناصر.
                </p>
                <p>
                  تُعدّ العناصر الغذائية الصغرى، مثل المعادن والفيتامينات،
                  بمثابة المفتاح لسلوك الجسم تجاه العناصر الكبرى، مثل البروتين
                  والكربوهيدرات. فهي تُحدد قوة انقباض العضلات، وتحويل
                  الكربوهيدرات إلى طاقةٍ بدلاً من تخزينها كدهون.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Tahini6} alt="about-section" />
              </div>
            </div>

            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Tahini7} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>ولكن، كيف يمكنك الحصول على تلك العناصر الحيوية؟</h3>
                <p>
                  نقدم لكِ طحينة منتور، مكمل خاص للياقة البدنية. غنية بأكثر من
                  35 معدن وفيتامين وحمض أميني بالإضافة الي الأحماض الدهنية
                  الصحية! بالإضافة إلى ذلك، فهي مدعمة بأفضل بروتين نباتي والذي
                  يتحول بمجرد دمجه مع دبس منتور إلى بروتين كامل مثل البروتين
                  الحيواني. وبها ستتخطي مرحلة الثبات لتُلاحظ تحسنًا ملحوظًا في:
                </p>
                <p>
                  <span>أداءك الرياضي:</span>
                  ستتمكن من ممارسة التمارين بكثافةٍ أكبر، وتحقيق نتائجٍ أفضل في
                  وقتٍ أقصر. كما سيتحسّن تدفق الدم لديك وقدرتك على التحمل.
                </p>
                <p>
                  <span>زيادة قوتك:</span>
                  يعمل محتوي الأحماض الأمينية بالطحينة على تحفيز نمو العضلات
                  وزيادة القوة الانفجارية للعضلات كما يعمل محتواها من الأرجينين
                  علي توسيع العروق والأوعية الدموية للوصول لذلك المظهر الخرافي.
                </p>
                <p>
                  <span>طاقتك ونشاطك:</span>
                  ستشعر بزيادةٍ ملحوظةٍ في طاقتك ونشاطك تدوم طويلاً دون الشعور
                  بالتعب أو الانهيار، مما يُساعدك على الاستمرار في التدريب دون
                  إرهاق، حيث يدعم إنتاج الطاقة على المستوى الخلوي، مما يحسن من
                  أدائك البدني والذهني.
                </p>
                <p>
                  <span>الاستشفاء العضلي:</span>
                  ستتعافى عضلاتك بشكلٍ أسرع بعد التمارين، مما يُقلّل من آلام
                  العضلات ويُساعدك على العودة إلى التدريب بشكلٍ أسرع.
                </p>
                <p>
                  <span>تركيزك:</span>
                  ستتمكن من التركيز بشكلٍ أفضل خلال التمارين، مما يُحسّن من
                  كفاءة أدائك.
                </p>
                <p>
                  <span>تحسن التمثيل الغذائي ورفع معدل الأيض:</span>
                  يرفع معدل الحرق وهرمون النمو معاً، مما يساعد على حرق الدهون
                  الزائدة وبناء كتلة عضلية صافية. كما سيُساعد جسمك على امتصاص
                  العناصر الغذائية من باقي الأطعمة واستغلالها بشكل أفضل. ومن بين
                  تلك العناصر البروتين ليعالج مقاومة امتصاص البروتين والاستفادة
                  حتى بالكميات الضئيلة منه.
                </p>
                <p>
                  <span>مزاج جيد ومستقر:</span>
                  تقلل من القلق والتوتر، وتعزز المزاج الهادئ وتحسن التركيز.
                </p>
                <p>
                  <span>التحكم بالشهية:</span>
                  تحكم في الرغبة الشديدة في تناول الطعام وتحسن صحة الجهاز الهضمي
                  وإعادة ضبط هرمون اللبتين المسؤول عن الشعور بالشبع مما يسرع
                  عملية التحكم في الوزن.
                </p>
                <p>
                  <span>غنية بالبروتين:</span>
                  المستخدم لبناء العضلات، وحرق الدهون، كما يجعلك تشعر بالشبع
                  لفترة أطول.
                </p>
                <p>
                  <span>غنية بالدهون الصحية:</span>
                  تحتوي طحينة منتور النقية على أهم الدهون الصحية وأحماض أوميغا 3
                  الدهنية التي تساعد على تقليل الالتهابات، وتحسين صحة القلب
                  والأوعية الدموية وتنظيم مستويات الكوليسترول. كما انها خالية من
                  أحماض أوميغا 6 الدهنية المسببة للالتهابات. لتساعدك على الشعور
                  بالشبع لفترة أطول، وتحسّن صحة القلب والأوعية الدموية، كما أنها
                  هي السائل الأساسي الذي يستخدمه الجسم في تصنيع الهرمونات
                  وفيتامين د وأغشية الخلايا.
                </p>
                <p>
                  <span>غنية بالعناصر الغذائية الأساسية:</span>
                  لتساعد على تحسين وظائف الجسم بشكل عام ودعم فقدان الوزن الصحي
                  وتعزيز الأداء الرياضي. كما تعمل الأحماض الأمينية الأساسية على
                  تحفيز نمو العضلات وبناء القوة.
                </p>
              </div>
            </div>

            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>طحينة منتور لجمال وتألق النجوم</h3>
                <p>
                  هل تبدو بشرتك شاحبة ويفتقر شعرك إلى اللمعان؟ تعانين من الجفاف
                  أو التجاعيد أو حب الشباب أو تساقط الشعر؟ تجعلك هذه المشاكل
                  تشعرين بعدم الثقة والإحباط من مظهرك؟
                </p>
                <p>
                  لسوء الحظ، يواجه الكثير مشاكل مستمرة في البشرة والشعر تستنزف
                  ثقتنا بأنفسنا وتجعلنا نشعر بأننا نظهر بأقل ما لدينا. لقد مررنا
                  جميعاً بهذه المشاكل! يمكن أن يكون الجفاف والتجاعيد وحب الشباب
                  والشعر المجعد والخفيف من الأمور المحبطة بشكل لا يصدق ويصعب
                  التعامل معها باستخدام المنتجات التقليدية التي غالباً ما تكون
                  غير كافية ولا تعطي نتائج مستدامة. مما يجعلك تشعرين بأنك ستظلين
                  تحت رحمة تلك المستحضرات؟
                </p>
                <p>
                  اكتشفي القوة التحويلية لطحينة منتور لتعزيز جمالك. تحتوي على
                  أكثر من 35 عنصرًا غذائيًا أساسيًا لتعزيز صحة البشرة والشعر
                  والحفاظ على توازن رطوبتهما:
                </p>
                <p>
                  <span>فيتامين E:</span>
                  يُعرف بفيتامين الجمال، يحارب التجاعيد ويرطب البشرة ويحسن
                  مرونتها ويعزز نمو الشعر ويحمي من التلف.
                </p>
                <p>
                  <span>فيتامين B:</span>
                  يجدد خلايا البشرة ويمنع تساقط الشعر.
                </p>
                <p>
                  <span>الزنك:</span>
                  يعالج حب الشباب ويحارب الالتهابات.
                </p>
                <p>
                  <span>النحاس:</span>
                  يحارب الشيب المبكر فهو المسؤول عن لون الشعر.
                </p>
                <p>
                  <span>الحديد:</span>
                  يحسن تدفق الدم إلى فروة الرأس ويعزز نمو الشعر.
                </p>
                <p>
                  <span>المغنيسيوم:</span>
                  يرطب البشرة والشعر ويحافظ على مرونتهما.
                </p>
                <p>
                  <span>الكالسيوم:</span>
                  يحسن صحة الأسنان والأظافر ولمعانها.
                </p>
                <p>
                  <span>مضادات الأكسدة:</span>
                  تحمي من الجذور الحرة وتؤخر علامات الشيخوخة.
                </p>
                <p>
                  <span>الدهون الصحية:</span>
                  تُحسّن مرونة البشرة وتعزز نمو الشعر. الطحينة لدينا هي قوة لصحة
                  البشرة والشعر.
                </p>
                <h5>فوائد الطحينة للبشرة</h5>
                <p>- ترطيب البشرة بعمق وشدها وعلاج التجاعيد.</p>
                <p>- محاربة الجذور الحرة وتحسين مرونة البشرة.</p>
                <p>- اضاءة البشرة وتوحيد لونها.</p>
                <p>- معالجة حب الشباب وتقليل من الالتهابات.</p>
                <p>- تنظيف البشرة والمحافظة على صحتها.</p>
              </div>
              <div className="paragraph-img">
                <img src={SesameOil} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section six */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>فوائد الطحينة للشعر</h3>
                <p>- تقوية الشعر وعلاج تساقطه.</p>
                <p>- تحفيز نمو الشعر وتكثيفه.</p>
                <p>- ترطيب الشعر وتقليل تجعده وجفافه.</p>
                <p>- ترطيب الشعر وتقليل تجعده وجفافه.</p>
                <p>- الوقاية من شيب الشعر وعلاجه وتعزيز لمعان الشعر.</p>
                <h5>زيت السمسم – الاعجاز</h5>
                <p>
                  حينما تخبرنا الأبحاث أن زيت السمسم هو أفضل مادة على الإطلاق
                  لبشرتك! قد تظن أنها مبالغة. ولكن عند فحص زيت السمسم تتبين
                  الحقيقة ونكتشف أخيرا سر صيته بدول آسيا من آلاف السنين. وستجد
                  حينها انه أقرب الزيوت الطبيعية من حيث تركيبته وطبيعته تشابها
                  بزيوت جلد الانسان، لتكتشف أنه الحل الأمثل لمختلف المشاكل التي
                  تعاني منها البشرة. وكما يعالج النحاس شيب الشعر علاج نهائي من
                  الداخل وليس مؤقتا مثل الصبغات. يعالج زيت السمسم أيضا البشرة
                  بنفس المبدأ بمدها بكل ما تحتاجه لتتحسن وتتطور نحو المثالية كحل
                  جري.
                </p>
                <p>
                  كما يعرف زيت السمسم بخصائصه المضادة للالتهابات! وذلك لثرائه
                  بأوميجا 3 المضادة للالتهابات ومضادات الأكسدة المختلفة وخلوه من
                  أوميجا 6 المسببة للالتهابات. لذلك فهو الخيار المثالي للبشرة
                  الحساسة، يهدئ البشرة المتهيجة ويقلل من احمرارها. كما أنه يعزز
                  مقاومة البشرة للعدوى. ولا يتسبب بانسداد المسام.
                </p>
                <h5>كيفية استخدام زيت طحينة السمسم كمستحضر تجميل طبيعي:</h5>
                <p>
                  للاستفادة من الفوائد التجميلية لطحينة السمسم، كل ما عليك ان
                  توفر للجلد والبشرة ما يحتاجونه من مغذيات، ويفضل أن يحدث ذلك
                  داخليا (من الجهاز الهضمي الي الجلد عبر الدم) وظاهريا (بامتصاص
                  الجلد لها بعد دهانه بها)، وذلك عبر تناول الطحينة مباشرة.
                  واستخدامها كقناع للوجه لتنظيف البشرة وتقشيرها.
                </p>
                <p>
                  للاستفادة من الفوائد التجميلية لطحينة السمسم، كل ما عليك ان
                  توفر للجلد والبشرة ما يحتاجونه من مغذيات، ويفضل أن يحدث ذلك
                  داخليا (من الجهاز الهضمي الي الجلد عبر الدم) وظاهريا (بامتصاص
                  الجلد لها بعد دهانه بها)، وذلك عبر تناول الطحينة مباشرة.
                  واستخدامها كقناع للوجه لتنظيف البشرة وتقشيرها.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={OilHair} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section seven */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Key} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>سر ابتسامة نجوم هوليوود المتألقة</h3>
                <p>
                  هل ترغبين في الحصول على أظافر قوية ولامعة وأسنان صحية وبيضاء؟
                  إليك حينة هو الحل!
                </p>
                <p>
                  الطحينة هي أغني طعام بالكالسيوم عالي الجودة علي الاطلاق
                  بالإضافة الي الفيتامينات والمعادن التي تساعد على تقوية الأظافر
                  والأسنان وتعزيز صحة اللثة. والأحماض الدهنية الأساسية التي ترطب
                  وتحافظ على صحة الأظافر.
                </p>
                <p>
                  طحينة السمسم وزيت السمسم هما مكونان طبيعيان غنيان بالعناصر
                  الغذائية التي تساعد على تعزيز صحة وجمال البشرة والشعر من
                  الداخل والخارج.
                </p>
                <p className="gold">جرب طحينة منتور اليوم لتري الفرق بنفسك</p>
              </div>
            </div>

            <div className="paragraph">
              <div className="paragraph-content">
                <p className="gold">“ Be Stronger - Be Smarter “</p>
                <img
                  className="img-full-width"
                  src={AdamGold}
                  alt="adam-gold"
                />
                <p className="gold">Adam – The Health engineers</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterAr />
    </>
  );
};

export default TahiniAr;
