import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../utilities/Navbar";
import Footer from "../../utilities/Footer";
import MentorSesame from "../../images/imgs/mentor-sesame.webp";
import TahiniImg from "../../images/imgs/tahini-3.webp";
import GreatBenefits from "../../images/imgs/great-benefits.webp";
import DontWait from "../../images/imgs/don't-wait.webp";
import Sesame from "../../images/imgs/sesame.webp";
import Nile from "../../images/imgs/nile.webp";
import Doctor from "../../images/imgs/doctor.webp";
import Tahini6 from "../../images/imgs/tahini-6.webp";
import SesameOil from "../../images/imgs/sesame-oil.webp";
import Key from "../../images/imgs/key.webp";
import OilHair from "../../images/imgs/hair.webp";
import Salad from "../../images/imgs/salad.webp";
import Waffles from "../../images/imgs/waffles.webp";
import Smoothies from "../../images/imgs/smoothies.webp";
import Diet from "../../images/imgs/diet.webp";
import AdamGold from "../../images/imgs/adam-gold.webp";
import { Helmet } from "react-helmet";

const Tahini = () => {
  return (
    <>
      <Navbar />
      <div className="about-page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Adam Superfoods | Tahini</title>
        </Helmet>
        <div className="about-container">
          <div className="about-intro">
            <div className="container">
              <div className="intro-info">
                <h3>Tahini ADAM</h3>
                <p>
                A culinary and nutritional masterpiece carefully crafted from the finest
                  <br />
                  sesame seeds.
                </p>
                <div className="work-with">
                  <Link to="">Discover Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section one */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={TahiniImg} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>Mentor sesame tahini from adam – Special superfood</h3>
                <p>
                In a world where rampant human interference with nature, genetic manipulation of our food sources, and the worst of the worst refined food categories that are completely devoid of nutritional value have become toxins in our daily lives, it has become increasingly difficult to get the nutrients we need even through a healthy diet alone.
                </p>
                <p>
                But what if there was a superfood that could provide a significant boost of vitamins, minerals and antioxidants in just one spoonful, the equivalent of half a kilo of meat or ten slices of liver?
                </p>
                <p>
                There are a few foods that contain many easily absorbed nutrients in high concentrations, known as superfoods. One of the most powerful of these rare foods is tahini, just like cane molasses.
                </p>
                <p>
                Of course, it is always important to make the right choice of tahini, starting from the way it is made, its purity, quality and the type of sesame used to enjoy its benefits and not be exposed to the harms of the usual additives such as flour, ground bread, palm oil, preservatives, texture stabilizers, soy and other harmful substances.
                </p>
                <p>
                Tahini is a great product! The result of grinding sesame seeds into a creamy paste that is high in nutrients and healthy fats, low in calories, and easy to use. 
                </p>
              </div>
            </div>

            <div className="paragraph right">
              <div className="paragraph-content">
                <p>
                Sesame is one of the oldest oilseeds used by our ancestors. The first discoveries of its place in the diet date back at least 5,500 years! From ancient times to the present, the regions where it was grown were given special status and used regularly in recipes, regions such as the countries of the Middle East and East Africa. Egypt, Palestine, Syria, Lebanon, Iraq and many more! Even in ancient Greece, there are many references to its use in nutrition in various ways, while Hippocrates and Galen used it in medicine. In Asian countries, sesame oil is widely recognized for its amazing benefits to the body! 
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Sesame} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section Two */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>Why Mentor Sesame Tahini?</h3>
                <p>
                <span>Rich in nutrients:</span>
                 Making it a comprehensive health booster.
                </p>
                <p>
                <span>Cold-pressed and raw:</span>
                 made through a gentle, cold-pressing process that preserves all the natural oils, nutrients, and flavors of the sesame seeds without exposure to heat or chemicals.
                </p>
                <p>
                <span>100% pure:</span>
                 It contains no stabilizers, preservatives or other additives, ensuring that you get the purest form of tahini, packaged in 100% pure form with raw sesame oil, so you can enjoy pure sesame oil in the same package!
                </p>
                <p>
                <span>Gluten Free:</span>
                 To enjoy its delicious taste and nutritional benefits without worry.
                </p>
                <p>
                <span>Powerful Anti-Aging:</span>
                 Inactivity, laziness, obesity, insulin resistance, fluid retention, blood pressure, diabetes, hormonal imbalance, heart disease, breast disease, osteoporosis, and cancerous tumors.
                </p>
                <p>
                <span>Suitable for everyone:</span>
                 Even for those on any type of diet, including keto and vegan diets.
                </p>
                <p>
                <span>Fully aligned with Adam's:</span>
                 Superfood - Effective – Has multiple and powerful therapeutic and preventive benefits - Safe to use - Comprehensive for each consumer's specific goals and easy to customize for each goal - Delicious.
                </p>
                <p>
                <span>Deliciously versatile:</span>
                 Sesame Tahini has a delicious and distinctive flavor and can be used in a variety of dishes.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={TahiniImg} alt="about-section" />
              </div>
            </div>
            <div className="paragraph">
            <div className="cards-container">
              <div className="card">
                <img src={Salad} alt="salad" />
                <h4>
                  Salad Dressings
                  <br/>
                  and Dips
                </h4>
                <p>
                Tahini adds a nutty, creamy texture to dressings and dips, enhancing their flavor and nutritional value.
                </p>
              </div>

              <div className="card">
                <img src={Waffles} alt="" />
                <h4>Waffles and cookies</h4>
                <br />
                <p>
                  Tahini can be used in cookies, cakes, and other baked treats, adding moisture and a rich nutty taste.
                </p>
              </div>

              <div className="card">
                <img src={Smoothies} alt="" />
                <h4>
                  Smoothies
                </h4>
                <p>
                  Blending tahini into smoothies provides a creamy texture and a boost of plant-based protein and healthy fats.
                </p>
              </div>

              <div className="card">
                <img src={Diet} alt="" />
                <h4>
                  Diet
                  <br />
                  Friendly
                </h4>
                <p>
                  Tahini is suitable for keto, vegan, and gluten-free diets, making it a versatile ingredient for various dietary needs.
                </p>
              </div>
            </div>
            </div>
          </div>
        </section>
        {/* section Three */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>Mentor Sesame Tahini</h3>
                <p>
                A culinary and nutritional masterpiece carefully crafted from the finest sesame seeds. This exquisite cream represents the perfect harmony of taste and nutritional value, forming a formidable duo of taste and nutritional value with Adam's Mentor Molasses. It is a good source of protein, minerals, fiber and healthy fats. When rich and sweet cane molasses meets tahini, the result is an irresistible combination of flavors and textures. This unique duo maximizes the concentration and variety of nutrients to fully meet the body's daily needs for all vital elements without the need for additional pills or supplements.
                </p>
                <p>
                This unique combination maximizes the concentration and diversity of nutrients to fully meet your body's needs. No more worrying about whether you're getting all the vitamins and minerals your body needs. Just enjoy the delicious taste and feel the vitality!
                </p>
                <p>
                The amazing combination of Mentor Cane Molasses and Sesame Tahini provides a whole range of essential nutrients, including protein, complex carbohydrates, healthy fats, fiber, vitamins, amino acids, and minerals.
                </p>
                <p>
                Just two tablespoons of molasses and two tablespoons of tahini will give you a whopping 92% of the iron, 23% of the calcium, 44% of the magnesium, 30% of the potassium, and 30% of the phosphorus your body needs naturally, easily, and fast! And that's not all! This incredible mixture is also packed with all other essential nutrients, giving you a huge boost of polyphenols, leucine, omega-3, thiamine (B1), riboflavin (B2), niacin (B3), vitamin B5, vitamin B6, choline, threonine, tryptophan, carotene, lysine, leucine, arginine, and more! 
                </p>
                <p>
                And there's more! This amazing blend is also brimming with all the other essential nutrients, giving you a huge boost of: polyphenols, leucine, omega-3, thiamine (B1), riboflavin (B2), niacin (B3), vitamin B5, vitamin B6, choline, threonine, tryptophan, carotene, lysine, leucine, arginine. 
                </p>
              </div>
              <div className="paragraph-img">
                <img src={MentorSesame} alt="about-section" />
              </div>
            </div>
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Nile} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                And the best part is that all of these amazing nutrients have a caloric content of less than 11% of your daily intake in a matrix with a very low glycemic index!
                </p>
                <p>
                This blend is the ultimate solution for any athlete or mom to ensure that their bodies and their children's bodies are getting the important nutrients they need without any harm from prolonged use, unlike pharmaceutical supplements. This makes this blend a complete pharmacy full of concentrated nutritional supplements with just this natural blend.
                </p>
                <h5>Mentor’ super foods are the perfect solution for those who are: </h5>
                <p>
                -	Looking for a healthy life style and targeting to reach to an ideal body faster and healthier without deprivation.
                </p>
                <p>
                -	Bodybuilders and Fitness Players
                </p>
                <p>
                -	Want to improve their overall health and vitality for various goals, whether it's building lean muscle mass with more protein or losing fat mass without negatively impacting their health and future metabolism.
                </p>
                <p className="gold">
                Join our revolution and start living your best life! Enjoy a healthy body that functions at peak efficiency!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section Four */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>Tahini’s great benefits for Overall Health:</h3>
                <h5>Cardiovascular Support</h5>
                <p>
                Tahini's mono and polyunsaturated fats promote heart health by lowering harmful LDL cholesterol while raising beneficial HDL cholesterol levels.
                </p>
                <h5>Blood Sugar and Hormone Regulation</h5>
                <p>
                The unique nutrient profile in tahini aids in balancing blood sugar and optimizing hormone production, reducing the risk of metabolic disorders like diabetes.
                </p>
                <h5>Bone Strength Enhancer</h5>
                <p>
                Rich in calcium, magnesium, and other minerals, tahini supports strong bones and increases bone density, preventing osteoporosis.
                </p>
                <h5>Fertility and Reproductive Booster</h5>
                <p>
                Tahini contains all compounds that boost fertility and sexual health in both men and women, supporting reproductive health and normal sperm production.
                </p>
                <h5>Anti-inflammatory and Antioxidant Powerhouse</h5>
                <p>
                The antioxidants and anti-inflammatory compounds in tahini protect cells from oxidative stress and chronic inflammation, reducing disease risk.
                </p>
                <h5>Digestive and Gut Health Aid</h5>
                <p>
                The fiber and beneficial fats in tahini promote a healthy gut microbiome, improving digestion and nutrient absorption.
                </p>
                <h5>Beauty Enhancer</h5>
                <p>
                Tahini's vitamins, minerals, and healthy fats nourish skin, hair, and nails, promoting a youthful and radiant appearance.
                </p>
                <h5>Antidepressant</h5>
                <p>
                Improves mood by supporting the release of serotonin, and balances hormones with ingredients that reduce anxiety and stress and improve sleep quality.
                </p>
                <h5>Improve muscle and nervous system function</h5>
                <p>
                Very rich in B-Vitamins which improve and support nervous functions and essential electrolytes to improve muscles functions and strength. Plus, it's fortified with the highest quality amino acids that improve blood flow and athletic performance.
                </p>
                <h5>Promote immune system health</h5>
                <p>
                Tahini is a great source of Zinc and antioxidants that support your immunity.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={GreatBenefits} alt="about-section" />
              </div>
            </div>

            <div className="paragraph">
              <div className="paragraph-img">
                <img src={DontWait} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>Mentor Tahini- An Exceptional Supplement</h3>
                <p>
                <span>What makes Mentor Tahini better than other supplements?</span> Mentor Tahini is packed with over 35 minerals, amino acids, healthy fatty acids and vitamins! It's an ideal source of the essential nutrients your body needs without the need to take any other pills or supplements, and prevents the harmful effects of long-term use of those pills. 
                </p>
                <p>
                <span>Better Alternative to Supplements:</span>
                 Unlike pills and pharmaceutical supplements that can be hard on the stomach and cause long-term side effects, Mentor Tahini is a natural and delicious superfood that provides your body with all the nutrients it needs without any risks. 
                </p>
                <p>
                <span>Absorption:</span>
                 Mentor Tahini is a natural source of nutrients which are better absorbed than supplements, which are difficult for the body to absorb, ensuring that they are better absorbed and fully utilized by the body.
                </p>
                <p>
                <span>Promotes Digestive Health and Metabolism:</span>
                 Mentor Tahini helps promote a healthy digestive system and improves your body's ability to absorb and utilize nutrients from other foods effectively.
                </p>
                <p>
                <span>A perfect supplement to your diet:</span>
                 Even if your diet isn't perfect, Mentor Molasses and Tahini helps you get the most out of your food by promoting better absorption and utilization of nutrients. Get the most out of your diet, even if it's not perfect. 
                </p>
                <p>
                <span>100 % Natural:</span>
                 Mentor Tahini is a natural, preservative-free product that is safe for long-term use.
                </p>
                <p className="gold">
                Tahini is the best ever source of Calcium, Zink, Iron, copper!
                </p>
              </div>
            </div>

            <div className="paragraph right">
              <div className="paragraph-content">
                <p>
                The perfect combination of nutrients in Mentor Tahini ensures that every athlete and mom can feel confident that their bodies and their children's bodies are meeting their daily nutritional needs, regardless of the rest of the foods they eat throughout the day. The variety of elements helps the body absorb them better.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Doctor} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section five */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Tahini6} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>Mentor Tahini for fitness</h3>
                <p>
                Struggling to see results from your workouts? Are you experiencing fatigue, slow recovery, or lack of motivation?  Pushing yourself hard at the gym, but never quite reaching your potential. Your muscles feel sore, your energy level plummets, and your mental focus wavers. It's discouraging to put in the effort without seeing any results.
                </p>
                <p>
                Introducing Mentor Tahini, the ultimate fitness superfood. Packed with over 35 minerals, amino acids, healthy fatty acids and vitamins! Plus, it's fortified with the highest quality plant protein, which turns into animal-like protein when combined with Mentor Molasses.
                </p>
                <p>
                <span>Stronger muscles:</span>
                 Essential amino acids stimulate muscle growth and build strength.
                </p>
                <p>
                <span>Faster Recovery:</span>
                 Repairs tissue and rebuilds muscle after intense exercise.
                </p>
                <p>
                <span>Improved Athletic Performance:</span>
                 Improves blood flow and endurance, giving you the energy to excel in your workout routines.
                </p>
                <p>
                <span>Stabilized Mental Health:</span>
                 Reduces anxiety and stress, promoting a calm mood and improved concentration.
                </p>
                <p>
                <span>High Fiber:</span>
                 Controls cravings, improves digestive health, and supports weight management.
                </p>
                <p>
                <span>High in Protein:</span>
                 Increases muscle mass, burns fat, and keeps you feeling fuller longer.
                </p>
                <p>
                <span>Rich in healthy fats:</span>
                 Adam's Pure Mentor Tahini contains healthy fats and omega-3 fatty acids, that help reduce inflammation, improve cardiovascular health, and regulate cholesterol levels. It's free of inflammatory omega-6 fatty acids.
                </p>
                <p>
                Healthy fats help you feel fuller for longer, improve cardiovascular health, and are the primary fluid used by the body to synthesize hormones, vitamin D, and cell membranes.
                </p>
                <p>
                <span>Rich in essential nutrients:</span>
                 Helps improve overall body function, support weight loss, and enhance athletic performance.
                </p>
                <p className="gold">
                Reduces fatigue and tiredness, making it easier to exercise regularly. Helps you burn fat more efficiently and lose weight in a healthy way.
                </p>
              </div>
            </div>

            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>
                Mentor Tahini for extreme beauty
                </h3>
                <p>
                Does your skin look dull and your hair lack shine. You might be struggling with dryness, wrinkles, acne, or hair loss. These issues can make you feel less confident and frustrated with your appearance.
                </p>
                <p>
                Unfortunately, many of us face persistent skin and hair problems that sap our confidence and make us feel less than our best. We've all been there! Dryness, wrinkles, acne, and hair thinning can be incredibly disheartening and difficult to manage with conventional products that often fall short.
                </p>
                <p>
                Discover the transformative power of Mentor Tahini for beauty. Packed with over 35 essential nutrients that promote skin and hair health, and maintain moisture balance:
                </p>
                <p>
                <span>Vitamin E:</span>
                 Known as the beauty vitamin, it fights wrinkles, moisturizes and improves skin elasticity while promoting hair growth and protecting against damage.
                </p>
                <p>
                <span>Vitamin B:</span>
                 Regenerates skin cells and prevents hair loss.
                </p>
                <p>
                <span>Zinc:</span>
                 Treats acne and fights inflammation.
                </p>
                <p>
                <span>Iron:</span>
                 Improves blood flow to the scalp and promotes hair growth.
                </p>
                <p>
                <span>Magnesium:</span>
                 Hydrates skin and hair.
                </p>
                <p>
                <span>Calcium:</span>
                 Improves tooth and nail health and shine.
                </p>
                <p>
                <span>Essential Fatty Acids:</span>
                 Nourish and maintain healthy skin and hair.
                </p>
                <p>
                <span>Antioxidants:</span>
                 Protect against free radicals, delaying signs of aging.
                </p>
                <p>
                <span>Healthy Fats:</span>
                 Improve skin elasticity and promote hair growth. our tahini is a powerhouse for skin and hair health
                </p>
              </div>
              <div className="paragraph-img">
                <img src={SesameOil} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section six */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={OilHair} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>Amazing skin benefits:</h3>
                <p>
                  - Deeply hydrates and reduces wrinkles.
                </p>
                <p>
                  - Fights free radicals and improves skin's elasticity.
                </p>
                <p>
                  - Brightens and evens skin tone.
                </p>
                <p>
                  - Treats acne and reduces inflammation.
                </p>
                <p>
                  - Purifies and keeps skin healthy.
                </p>
                <h5>Amazing benefits for hair:</h5>
                <p>
                  - Strengthens and reduces hair loss.
                </p>
                <p>
                  - Stimulates growth and thickens hair.
                </p>
                <p>
                  - Moisturizes and reduces frizz and dryness.
                </p>
                <p>
                  - Treats dry, itchy scalp.
                </p>
                <p>
                  - Adds natural shine.
                </p>
                <h5>Sesame oil – The Miracle:</h5>
                <p>
                We're thrilled to tell you that sesame oil is the best thing ever for your skin! It's the closest natural oil in terms of its composition and nature to the ideal human skin oils, and it's a total solution for various conditions because it provides the skin with everything it needs and the only direction towards health.
                </p>
                <p>
                It's no wonder it's known for its anti-inflammatory properties! It's the ideal choice for sensitive skin, soothing irritated skin and reducing redness.
                </p>
                <p>
                It also boosts the skin's resistance to infection and promotes hair growth and prevents hair loss.
                </p>
                <p>
                Rich in Vitamin E, non-comedogenic and chemical free, it's perfect for sensitive skin and promotes hair growth.
                </p>
                <h5>How to use Tahini and sesame oil:</h5>
                <p>
                <span>Sesame tahini:</span>
                 can be eaten directly or added to various dishes. Also can be used as a facial mask to cleanse and exfoliate the skin.
                </p>
                <p>
                <span>Sesame oil can be used:</span>
                 as a moisturizer for the face and body, a makeup remover or home remedy for acne. And as a hair oil to moisturize and strengthen hair. Or as a home remedy for dandruff.
                </p>
                <p className="gold">
                Super stars’s secret to healthy nails and btight teeth!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section seven */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Key} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                Do you want strong, shiny nails and healthy, white teeth?  Mentor Tahini is the answer!
                </p>
                <p>
                Mentor Tahini is rich in calcium, vitamins and minerals to help strengthen nails, teeth and promote gum health. In addition to the essential fatty acids, which moisturizes and keeps nails healthy.
                </p>
                <p>
                Adam's Sesame Tahini and Sesame Oil are two natural ingredients rich in nutrients that help promote healthy, beautiful skin and hair from the inside out.
                </p>
                <p className="gold">
                Try it today and see the difference!
                </p>
              </div>
            </div>
            
            <div className="paragraph">
              <div className="paragraph-content">
                <p className="gold">“ Be Stronger - Be Smarter “</p>
                <img
                  className="img-full-width"
                  src={AdamGold}
                  alt="adam-gold"
                />
                <p className="gold">Adam – The Health engineers</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Tahini;
