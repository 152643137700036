import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../utilities/Navbar";
import Footer from "../../utilities/Footer";
import AboutSection from "../../images/imgs/about-section.webp";
import AboutSection2 from "../../images/imgs/design-4.webp";
import DiseasesAtack from "../../images/imgs/diseases-atack.webp";
import HealthyPill from "../../images/imgs/healthy-pill.webp";
import Nile from "../../images/imgs/nile.webp";
import Doctor from "../../images/imgs/doctor.webp";
import Running from "../../images/imgs/running.webp";
import pillCard from "../../images/imgs/pill-card.webp";
import Immunity from "../../images/imgs/immunity-card.webp";
import Suger from "../../images/imgs/suger-card.webp";
import Stress from "../../images/imgs/stress-card.webp";
import Products from "../../images/imgs/about-products.webp";
import World from "../../images/imgs/adam-world.webp";
import Design8 from "../../images/imgs/design-8.webp";
import Team from "../../images/imgs/team.webp";
import Team2 from "../../images/imgs/team-2.webp";
import GoldenHeart from "../../images/svg/heart-gold.svg";
import Fruits from "../../images/svg/fruits.svg";
import Magnet from "../../images/svg/magnet.svg";
import Innovation from "../../images/svg/innovation.svg";
import Bottle from "../../images/svg/bottle.svg";
import Nature from "../../images/svg/nature.svg";
import Testimonials from "../../components/Home/Testimonials";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <div className="about-page">
      <Helmet>
            <meta charSet="utf-8" />
            <title>Adam Superfoods | About Us</title>
      </Helmet>
        <div className="about-container">
          <div className="about-intro">
            <div className="container">
              <div className="intro-info">
                <h3>ABOUT US</h3>
                <p>
                  Adam For Foodstuffs Company
                  <br />
                  is a leading company In the field of producing molasses and
                  Egyptian tahini
                  <br />
                  With unparalleled
                  <span>Quality</span>
                </p>
                <div className="work-with">
                  <Link to="">WORK WITH US</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph right">
            <div className="paragraph-img">
                <img src={AboutSection} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>Our Philosophy</h3>
                <p>
                  We believe that health, strength, and fitness are the most
                  important pillars of happiness in our lives and a major goal
                  for many. We also believe that the way to achieve them is not
                  through harsh diets that only work for a few for a limited
                  time before their health deteriorates and refraining from the
                  pleasure of eating what we love. Therefore, Adam has adopted a
                  unique philosophy that focuses on combining complete nutrition
                  and deep healing without compromising pleasure.
                </p>
              </div>
            </div>

            <div className="paragraph">
              <div className="paragraph-content">
                <p>
                  We have developed and produced several superfoods that are a
                  perfect alternative to harmful substances we often consume on
                  a daily basis, in a pure and natural form, in addition to the
                  ease of customization and versatility. Our goal is to provide
                  superfoods that we enjoy eating and meet our health goals.
                </p>
                <p>
                  The earth still provides foods that have not been manipulated
                  and have maintained their natural raw state, which contain
                  high concentrations of many vital elements to show us how much
                  we need them in our days to get what has been taken from us.
                  And have We just need to handle these components with
                  simplicity and skill.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={AboutSection2} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section Two */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>Fact of Diseases</h3>
                <p>
                  The human body is an exquisitely designed, tough and resilient
                  creature, weaving a complex system that was divinely designed
                  to perfection. Each cell and organ in this complex machine
                  requires specific nutrients to function properly.
                </p>
                <p>
                  Throughout history, nature provided these elements through
                  whole foods. Humans lived in harmony with nature and enjoyed a
                  long life without disease or aging until modern practices
                  disrupted this harmony.
                </p>
                <p>
                  Over time, humans have changed the food they eat. This has led
                  to serious negative consequences, such as the loss of the
                  nutritional value of our food. This is the root of our health
                  issues today. When the body lacks a vital element, it affects
                  organ function and leads to disease. On the other hand, toxins
                  have increased around us.
                </p>
                <p>
                  Adam had the foresight to see that these practices were
                  disastrous and that only humans would reap the bitter fruits.
                  We are now seeing the effects, like more diseases like cancer,
                  heart disease and diabetes. And it became too hard to find
                  healthy food that doesn't make us sick.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={DiseasesAtack} alt="about-section" />
              </div>
            </div>
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={HealthyPill} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                  While the majority remained on lifelong medications for
                  chronic diseases that don't address the root cause of their
                  disease. Some have resorted to taking lab-made supplements,
                  which also pose many risks when used long-term. We believed
                  there was a better way.
                </p>
                <p>
                  So what if there was a superfood that could give you a massive
                  boost of essential nutrients in just one tablespoon. That's
                  equivalent to half a kilo of meat or ten slices of liver!
                </p>
                <p>
                  That's why we're here to offer a comprehensive solution to
                  most of the issues we face today! Our products are a specially
                  designed natural matrix for today's age. They're the result of
                  concentrating tons of rich botanicals into a few grams to form
                  a unique high-density blend of vital nutrients and active
                  compounds that work together harmoniously to provide a wide
                  range of health benefits and promote health and wellness at
                  concentrations higher and more comprehensive than any
                  pharmaceutical supplement. Most importantly, these products
                  are not only packed with health and therapeutic benefits, but
                  they are also delicious, versatile, and easy to customize to
                  suit your personal goals. You can enjoy them on their own or
                  add them to your favorite daily meals. You can use them for
                  healthy weight loss or take them at a higher frequency to
                  treat thinness in a healthy way. They are completely safe for
                  health and long-term use and are suitable for all ages and
                  diets.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section Three */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>Our Vision</h3>
                <p>
                  A world where everyone enjoys the benefits of modern
                  civilization and the health of our ancestors. We're on a quest
                  to elevate our species to the best version of ourselves!
                </p>
              </div>
              <div className="paragraph-img">
                <img src={AboutSection} alt="about-section" />
              </div>
            </div>
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Nile} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>A journey from the Nile land to a global revolution</h3>
                <p>
                  In Egypt, where nature meets history on the banks of the Nile
                  Valley, our story was born. With a noble mission and a bold
                  dream to redefine the basics of health and wellness and make
                  the world a place where everyone can enjoy the civilization of
                  today and the health of our ancestors, and elevate the human
                  race to the best version of itself.
                </p>
                <p>
                  In 1995, Adam Foods was established with a mission to make
                  health and wellness accessible to everyone, while simplifying
                  the transition to a healthy lifestyle.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section Four */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>Our Mission</h3>
                <p>
                  Our mission is to educate current and future generations on
                  the basics of health and wellness, as well as the causes of
                  disease. While providing effective solutions that are easy to
                  use and help people achieve their goals and live happier,
                  healthier lives.
                </p>
                <p>
                  After years of research and study, Adam identified five main
                  factors that summarize the causes of disease: unhealthy eating
                  habits, nutrient deficiencies, toxins, germs, and stress. And
                  95% of the world's diseases can be easily overcome just by
                  addressing these five points.
                </p>
                <p>
                  It is now clear that the food consumed is the focal point, the
                  dominant factor and the real key. By replacing refined and
                  frequently used toxins throughout the day such as white sugar,
                  etc. with the original, high-value varieties, the following is
                  automatically achieved:
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Doctor} alt="about-section" />
              </div>
            </div>
            <div className="cards-container">
              <div className="card">
                <img src={pillCard} alt="" />
                <h4>
                  Treating
                  <br />
                  Nutrient
                  <br />
                  Deficiencies
                </h4>
                <p>
                  Provision of nutrients to the body,thereby addressing
                  deficiencies in nutrient.
                </p>
              </div>

              <div className="card">
                <img src={Immunity} alt="" />
                <h4>Immunity</h4>
                <br />
                <p>
                  When the body gets nutrients it needs, the immunity works
                  well, to resist the effects of germs.
                </p>
              </div>

              <div className="card">
                <img src={Suger} alt="" />
                <h4>
                  Reducing
                  <br />
                  toxins
                </h4>
                <p>
                  Minimize the food toxins we frequently consume on a daily
                  basis.
                </p>
              </div>

              <div className="card">
                <img src={Stress} alt="" />
                <h4>
                  Relieve
                  <br />
                  Stress
                </h4>
                <p>Eating healthy foods reduces stress and tension levels</p>
              </div>
            </div>

            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Running} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                  In this way, most of the major causes of disease are overcome
                  and we have moved from a state of disease to a state of
                  wellness and prevention. To overcome the rest and move to the
                  advanced stage and get the perfect body, there are three main
                  pillars to follow that together form the corners of the
                  fitness triangle: Physical activity - Proper and complete
                  nutrition – High quality sleeping.
                </p>
                <p>
                  The blind focus on macronutrients (carbohydrates - protein)
                  without taking into account the most important micronutrients
                  (minerals - vitamins - healthy fats), which guide the
                  macronutrients and control their behavior inside the body,
                  leads to a serious imbalance in the diet and the emergence of
                  weaknesses in it, which causes:
                </p>
                <div className="small-card-wraper">
                  <div className="small-card">
                    <h4>First rib collapse</h4>
                    <p>
                      Lack of energy, decreased muscle strength, and inability
                      to exercise.
                    </p>
                  </div>

                  <div className="small-card">
                    <h4>Second rib collapse</h4>
                    <p>
                      Metabolic problems, poor protein absorption, hormonal
                      issues, and a slower metabolism lead to more energy loss,
                      muscle breakdown, and more fat.
                    </p>
                  </div>

                  <div className="small-card">
                    <h4>Third rib collapse</h4>
                    <p>
                      Muscle tension, insomnia, loss of deep sleep, relaxation,
                      and recovery lead to muscle atrophy and increased stress
                      and cortisol levels.
                    </p>
                  </div>
                </div>
                <p>
                  Thus, we also conclude that the quality of food is the most
                  important corner of the fitness triangle as well.
                </p>
                <p>
                  Therefore, a new mindset was needed to shatter the old
                  deceptive mentality and build a new mindset based on knowledge
                  and awareness, a mindset that realizes that the path of health
                  and the path of fitness are one and the same, and that the
                  ideal body is reached by following the path of health. Health
                  is the foundation and disease is the deviation and exception.
                  It's a different approach that leads to a better life in an
                  easier way.
                </p>
                <p>
                  With the rise of the health food wave and the growing concern
                  about the harmful effects of traditional food production
                  methods, many alternative health foods have emerged. However,
                  it must be acknowledged that their quality, especially in
                  terms of taste, has fallen short of what the consumer is
                  looking for: Either nutritional value or taste, and this is no
                  secret. In addition, it will still be difficult to get the
                  nutrients our bodies need through diet alone.
                </p>
              </div>
            </div>

            <div className="paragraph right">
              <div className="paragraph-content">
                <p>
                  We believe that any successful change in a person's life
                  begins with replacing some repetitive negative habits with
                  better ones. This is a fundamental principle that the company
                  keeps in mind when it comes to correcting and changing
                  community members' lives for the better, without disrupting
                  their lifestyle.
                </p>
                <p>
                  <span>The power of impact:</span>
                  To have a radical impact on people's lives with a few number
                  of products.
                </p>
                <p>
                  <span>Superfood:</span>
                  To provide highly concentrated levels of many essential
                  nutrients.
                </p>
                <p>
                  <span>Prevention:</span>
                  To promote disease prevention.
                </p>
                <p>
                  <span>Multiple benefits:</span>
                  To provide multiple health and therapeutic benefits
                  simultaneously.
                </p>
                <p>
                  <span>Special abilities:</span>
                  To boost energy, improve physical and mental performance,
                  manage weight, balance hormones, and promote a sense of
                  well-being.
                </p>
                <p>
                  <span>Easy and versatile:</span>
                  They are simple to integrate into our daily routines and can
                  offset the cumulative impact of frequent toxins with
                  cumulative benefits.
                </p>
                <p>
                  <span>Comprehensive:</span>
                  Suitable for diverse nutritional needs and lifestyles and can
                  be tailored to align with various overall objectives.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Doctor} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section five */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Products} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>Our Products</h3>
                <p>
                  One of the factors that inspired us to select products for our
                  specialty is their ability to entice the peoples to consume
                  them, even if they are not fully aware of their nutritional
                  importance. Cane molasses and sesame tahini are naturally
                  popular with the peoples.
                </p>
                <p>
                  However, the consumer may not be aware of their nutritional
                  value as a superfood, and with the proliferation of
                  adulterated varieties, he may be sure that he is not buying
                  them in their pure form and knows that they are usually mixed
                  with several other substances by traders, which led to the
                  spread of inferior types in order to cover the demand that was
                  not intended for superfoods from the beginning. That's why we
                  decided to offer our products in their purest form, without
                  any additives or preservatives. This way, people can enjoy
                  their delicious taste and many health benefits, even if they
                  are not aware of their nutritional value.
                </p>
                <p>
                  From here, a journey of our continuous innovation began, in
                  which we thrive on offering delicious, nutritious products
                  that are free of added sugar, preservatives and chemicals, to
                  be a faithful companion to your health.
                </p>
              </div>
            </div>

            <div className="paragraph right">
              <div className="paragraph-content">
                <p>
                  Adam has focused its efforts on developing the most important
                  alternatives to the strategic and 'harmful' commodities that
                  the people consume throughout their day and working to provide
                  an irresistible alternative that overflows with nutritional
                  values in a single product that includes taste, quality,
                  nutritional value and versatility to open a gateway to a world
                  of wellness and enjoyment of life. It's a health journey that
                  starts with a spoon.
                </p>
                <p>
                  Our products quickly gained global consumer preference.
                  Consumers from all over the world chose them because our
                  products met their health goals and because they tasted
                  amazing and demonstrated the richness of the pure image of our
                  products.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={pillCard} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section six */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={World} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>Adam around the world</h3>
                <p>
                  Adam Foodstuffs is a global success story! Since its first
                  year, Adam has been exporting its products to many different
                  countries with the most challenging health requirements until
                  became the favorite of those peoples with a customer retention
                  rate, whether consumers or distributors, that reaches more
                  than 98%.
                </p>
                <p>
                  Adam for Foodstuffs has been privileged to contribute to the
                  building and strengthening of many global brands.
                </p>
                <p>
                  Through mutual cooperation, these brands have gained the favor
                  of their audiences, achieved broad and sustainable successes,
                  and have been the fastest growing.
                </p>
                <p>
                  The companies that own these brands produce a wide range of
                  food products of the highest quality. However, when it comes
                  to molasses or tahini, they must be aware that despite their
                  high value, they require special expertise and skill to
                  produce them in a way that combines taste, nutritional value,
                  and high quality. This is why distinguishing their brand with
                  an exceptional and unique product required joint cooperation
                  for Adam to do the production and packaging, and they
                  distributed it within their country under their own brand.
                  This approach enabled them to offer unique products that were
                  exclusive to Adam until they were able to gain the trust of
                  consumers interested in high-quality products, and our
                  products became the spearhead of their product portfolio all
                  along the line.
                </p>
              </div>
            </div>
            <Testimonials />
          </div>
        </section>
        {/* section seven */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Design8} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>Our unique methodology </h3>
                <p>
                  Our strength lies not only in our unique products, but also in
                  our methodology that makes us different. By following the
                  “focus strategy” since our inception, we have acquired a
                  unique manufacturing secret and have been able to take the
                  quality and specifications of our products to the next level,
                  making them incomparable to any other version.
                </p>
                <p>
                All Adam products undergo extensive and multiple quality checks by various parties in Egypt and in the exporting country to ensure that they meet the highest quality standards, as we are committed to making health and wellness accessible to everyone.
                </p>
              </div>
            </div>
            <div className="paragraph right">
            <div className="div-icons-wrapers">
                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={GoldenHeart} alt="golden-heart" />
                      <h4>Superior quality</h4>
                    </div>
                    <p>
                      Strict adherence to the highest quality standards at all
                      stages of production.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={Fruits} alt="" />
                      <h4>Preserve nutritional value</h4>
                    </div>
                    <p>
                    Special processing to maintain high nutritional value.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={Magnet} alt="magnet" />
                      <h4>Just physical Processing</h4>
                    </div>
                    <p>
                    We don't use high heat or any chemicals that interfere with our health message.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                    <img src={GoldenHeart} alt="golden-heart" />
                      <h4>Purity</h4>
                    </div>
                    <p>
                    Our products are 100% pure and completely free of added sugars, added oils, preservatives and chemicals.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={Innovation} alt="innovation" />
                      <h4>Continuous innovation</h4>
                    </div>
                    <p>
                    We keep innovating and developing new products to meet our customers' needs.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={Bottle} alt="" />
                      <h4>Thoughtful processing & packaging</h4>
                    </div>
                    <p>
                    We offer our products in carefully designed packaging that preserves their taste and quality, and stay away from plastic packaging that is questionable for long-term health and repeated use.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={Nature} alt="" />
                      <h4>Nature</h4>
                    </div>
                    <p>
                    We use only the best, non-GMO crops that are treated with care to preserve their nutritional value.
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </section>
        {/* section Eight */}
        <section className="paragraphs-wraper">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>Our responsibilities to society</h3>
                <p>
                All of us at Adam company realize that we have a great responsibility to our community and public awareness to achieve our mission. Therefore, we are now focusing all our efforts on raising health awareness among the youth and future generations and providing appropriate solutions such as healthy alternatives, in addition to our efforts to support the national economy by using local raw materials, machinery and production materials.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Team} alt="about-section" />
              </div>
            </div>
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Team2} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h5>1- Awareness</h5>
                <p>
                We work to educate youth and future generations about the importance of maintaining good mental and physical health and provide them with the awareness and culture necessary to change their health for the better in every way possible, including supporting and sponsoring health and sports programs, activities and events.
                </p>
                <h5>2- Supporting the national economy</h5>
                <p>
                All production materials and machinery we use are all Egyptian, from food raw materials to glass jars, lids, cartons, labels, even the machinery used in all stages of production are all Egyptian to support the local economy and emphasize the ability of Egyptian industry and agriculture to compete globally at the highest levels.
                </p>
              </div>
            </div>
            <div className="paragraph right">
              <div className="paragraph-content">
                <p>
                Adam has not only provided excellent food products, but has also sought to build a healthy community that is aware of the importance of proper nutrition. By supporting health and exercise programs and sponsoring researchers in the field of therapeutic nutrition, our company aims to bring about real change in dietary habits and empower people to make smart, healthy choices.
                </p>
                <p>
                Adam is a dream come true, a dream that expresses the thousands of parties involved - doctors, nutritionists, farmers, employees and workers in various fields - with a perfect product and honest advice. This common dream aims to raise the Egyptian flag high in the international markets, emphasizing that "Made in Egypt" is not just a slogan, but a sign of excellence and quality.
                </p>
                <p>
                Adam has succeeded in making its products superior to foreign products in all the markets it has entered and has become synonymous with quality and excellence.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={AboutSection} alt="about-section" />
              </div>
            </div>
            <div className="paragraph">
              <div className="paragraph-img">
                <img src={AboutSection} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                Adam's ambition is still aspirational, and it's clear he's going to achieve it! He's got some big plans to develop innovative products, explore new markets, build a global network of partners interested in human health and well-being, and bring his products to every corner of the world. The company is currently focusing its efforts on investing in experimentation and testing with top nutritionists and food preparation experts from around the world. They're on a mission to offer a wide range of healthy products that taste better than anything else on the market. They're filling all disease-causing gaps and catering to all tastes!
                </p>
                <p>
                We invite you to join our family, the Adam family, where we are united by a different mindset based on science and knowledge, a mindset that realizes that the ideal body is reached by following the path of health. And that health is the foundation and disease is the deviation and exception 
                </p>
                <p>
                It's a different approach that leads us to change the world for the better.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
