import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../utilities/Navbar";
import FooterAr from "../../utilities/FooterAr";
import AboutSection from "../../images/imgs/about-section.webp";
import AboutSection2 from "../../images/imgs/design-4.webp";
import DiseasesAtack from "../../images/imgs/diseases-atack.webp";
import HealthyPill from "../../images/imgs/healthy-pill.webp";
import Nile from "../../images/imgs/nile.webp";
import Doctor from "../../images/imgs/doctor.webp";
import Running from "../../images/imgs/running.webp";
import pillCard from "../../images/imgs/pill-card.webp";
import Immunity from "../../images/imgs/immunity-card.webp";
import Suger from "../../images/imgs/suger-card.webp";
import Stress from "../../images/imgs/stress-card.webp";
import Products from "../../images/imgs/about-products.webp";
import World from "../../images/imgs/adam-world.webp";
import Design8 from "../../images/imgs/design-8.webp";
import Team from "../../images/imgs/team.webp";
import Team2 from "../../images/imgs/team-2.webp";
import GoldenHeart from "../../images/svg/heart-gold.svg";
import Fruits from "../../images/svg/fruits.svg";
import Magnet from "../../images/svg/magnet.svg";
import Innovation from "../../images/svg/innovation.svg";
import Bottle from "../../images/svg/bottle.svg";
import Nature from "../../images/svg/nature.svg";
import Testimonials from "../../components/Home/Testimonials";
import { Helmet } from "react-helmet";

const AboutUsAr = () => {
  return (
    <>
      <Navbar />
      <div className="about-page">
      <Helmet>
            <meta charSet="utf-8" />
            <title>Adam Superfoods | من نحن</title>
      </Helmet>
        <div className="about-container">
          <div className="about-intro ar">
            <div className="container">
              <div className="intro-info ar">
                <h3>من نحن</h3>
                <p>
                  شركة آدم للمواد الغذائية هي شركة رائدة <br />
                  في مجال إنتاج العسل الأسود والطحينة المصرية <br />
                  <span>بجودة لا مثيل لها</span>
                </p>
                <div className="work-with">
                  <Link to="">أعمل معنا</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-content">
                <h3>فلسفتنا</h3>
                <p>
                  نؤمن بأن الصحة والقوة واللياقة البدنية أهم ركائز السعادة
                  بحياتنا وهدف رئيسي للعديد، وانه لا يمكن ان يكون السبيل الوحيد
                  لتحقيقهما عبر الحميات الغذائية القاسية والتي لا تنجح سوي مع
                  القلة لفترة محدودة قبل ان تتدهور صحتهم، والامتناع عن متعة
                  تناول ما نحبه. لذا تبنت آدم فلسفة فريدة تركز على الجمع بين
                  التغذية الكاملة والشفاء العميق دون المساس بالمتعة.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={AboutSection} alt="about-section" />
              </div>
            </div>

            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={AboutSection2} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                  انطلاقًا من هذه النقطة. قمنا بتطوير ألعديد من الأغذية فائقة
                  القيمة الغذائية وانتجناها بصورة مثالية لتصبح بديل مثالي للعديد
                  من المواد الضارة المتكرر استهلاكها يوميا وبصورة نقية وطبيعية
                  100%، بالإضافة الي سهولة توظيفها حسب الهدف وتعدد الاستخدام
                  وبالطبع الاستخدام الآمن. وذلك من أجل توفير مواد فائقة القيمة
                  نستمتع بتناولها وتحقق أهدافنا الصحية بنفس الوقت. فالأرض ما
                  زالت تثمر بالأطعمة التي لم يتم التلاعب بها وحافظت على حالتها
                  الخام الطبيعية والتي تحتوي عل تركيزات عالية بالعديد من العناصر
                  الحيوية ليظهر مدي احتياجنا لها بأيامنا تلك لتعوضنا عما سلب
                  منّا، وما علينا سوي نتعامل مع هذه المكونات بكل بساطة ومهارة.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section Two */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-content">
                <h3>حقيقة الأمراض</h3>
                <p>
                  خُلق جسم الإنسان ذلك المخلوق البديع بتصميم رائع يمتاز بقدرته
                  الفائقة على التعافي والصمود لينسج نظامًا معقدًا خُلق بإتقان
                  إلهي. تتطلب كل خلية وعضو في هذه الآلة المعقدة عناصر غذائية
                  محددة ليعمل بشكل صحيح.
                </p>
                <p>
                  وعلي مر التاريخ، وفرت الطبيعة تلك العناصر من خلال الأطعمة
                  الكاملة. وعاش الانسان في وئام تام مع الطبيعة وتمتع بحياة طويلة
                  دون امراض او شيخوخة حتي قامت الممارسات الحديثة بتخريب هذا
                  التناغم.
                </p>
                <p>
                  ومع مرور الوقت، تفشى التدخل البشري الغشيم بالطبيعة والتلاعب
                  الجيني بمصادر طعامنا اليومي وتوغل أسوء فئاته المُكررة الضارة
                  والمجردة من أي قيمه غذائية بحياتنا اليومية وتحولها لسموم
                  نتناولها بشكل مستمر، وقد أدى هذا التغيير الجذري في إنتاج
                  الغذاء إلى عواقب سلبية وخيمة، مثل فقدان القيمة الغذائية
                  لطعامنا. وهنا تكمن جذور مشاكلنا الصحية اليوم، فعند افتقار
                  الجسم لأحد العناصر الحيوية، فإن ذلك يؤثر سلباً على وظائف
                  الأعضاء ويؤدي إلى ظهور المعاناة وأعراض المرض، والذي بحقيقته ما
                  هي الا مؤشرات على خلل في المدخلات الغذائية. ومن ناحية أخرى،
                  تزايدت السموم من حولنا والإفراط في استخدام المواد الحافظة
                  والمعززات الصناعية في معالجة الأغذية واستخدام الأسمدة
                  الكيماوية والمبيدات الحشرية لتكثيف المحاصيل.
                </p>
                <p>
                  كان لدي آدم رؤية واعية لكارثية تلك الممارسات، وأن الانسان وحده
                  من سيجني ثمارها المرّة. وها نحن الآن نشهد أثارها مثل
                  الارتفاعات غير المسبوقة بمعدلات الأمراض المزمنة مثل السرطان
                  وأمراض القلب والسكري، وظهور أمراض جديدة. وأصبح العثور على
                  أطعمة صحية تلبي للجسم احتياجاته من المغذيات وتقلل من اثار تلك
                  السموم امر بغاية الصعوبة.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={DiseasesAtack} alt="about-section" />
              </div>
            </div>
            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={HealthyPill} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                  وبينما استمر الأغلبية علي أدوية لأمراض مزمنة لا يتوقفون عنها
                  طوال حياتهم ولا تعالج السبب الرئيسي لمرضهم. ولجأ البعض الي
                  تناول المكملات الغذائية المحضرة معملياً والتي تشكل أيضا مخاطر
                  عديدة عند استخدامها على المدي الطويل، كنا نؤمن أن هناك طريقة
                  أفضل.
                </p>
                <p>
                  فماذا لو كان هناك طعام خارق يمنحك دفعة هائلة من الفيتامينات
                  والمعادن ومضادات الأكسدة في ملعقة واحدة فقط تعادل نصف كيلو من
                  اللحم أو عشر شرائح من الكبد.
                </p>
                <p>
                  لذلك جئنا لنقدم حلاً شاملاً لأغلب المشاكل التي تواجهنا اليوم.
                  فمنتجاتنا عبارة مصفوفة طبيعة مصممة خصيصا لعصرنا الحالي، ناتجة
                  عن تركيز أطنان من النباتات الغنية في بضع جرامات مكونة مزيج
                  فريد عالي الكثافة من العناصر الغذائية الحيوية والمركبات النشطة
                  التي تعمل معًا بتناغم لتوفير مجموعة واسعة من الفوائد الصحية
                  وتعزيز الصحة والعافية بتركيزات اعلي واشمل من كافة المكملات
                  الصيدلانية. والأهم انه بجانب قدراتها الصحية والعلاجية العديدة،
                  فإنها لذيذة ومتنوعة وتتناسب مع جميع الأذواق وسهلة التوظيف حسب
                  الهدف الشخصي لكل مستهلك. فيمكنك الاستمتاع بها بمفردها أو
                  إضافتها إلى وجباتك اليومية المفضلة، كما يمكنك توظيفها لخسارة
                  الوزن بشكل صحي او تناولها بتردد أعلي لعلاج النحافة أيضا بصورة
                  صحية. كما انها آمنة تمامًا على الصحة والاستخدام لفترة طويلة
                  ومناسبة لجميع الأعمار والحميات الغذائية المختلفة.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section Three */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-content">
                <h3>رؤيتنا</h3>
                <p>
                  نسعى إلى جعل العالم مكانًا يتمتع فيه الجميع بحضارة اليوم وصحة
                  الأجداد، وأن نرتقي بجنسنا إلى أفضل نسخة منا.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={AboutSection} alt="about-section" />
              </div>
            </div>
            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={Nile} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>رحلة من أرض النيل إلى ثورة عالمية</h3>
                <p>
                  في قلب مصر، حيث تلتقي الطبيعة بالتاريخ ملقيةً بكنوزها على ضفاف
                  وادي النيل، نبتت قصتنا. حاملة رسالة نبيلة وحلمًا جريئًا لإعادة
                  تعريف أسس الصحة والعافية وجعل العالم مكانًا يتمتع فيه الجميع
                  بحضارة اليوم وصحة الأجداد، وأن نرتقي بالجنس البشري إلى أفضل
                  نسخة منه.
                </p>
                <p>
                  تأسست شركة آدم للمواد الغذائية منذ عام 1995 حاملة على عاتقها
                  مهمة جعل الصحة والعافية في متناول الجميع، هادفة إلى جعل هذا
                  الاختيار متاحًا دون التسبب بعقدة تجاه فكرة التحول الى اسلوب
                  حياة صحي.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section Four */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph right">
              <div className="paragraph-content">
                <h3>رسالتنا</h3>
                <p>
                تتمثل رسالتنا بتوعية الأجيال الحالية والقادمة بمبادئ وأساسيات الصحة وتحذيرهم من مسببات الامراض مع توفير حلول فعالة بأعلى جودة لجذبهم نحو تحقيق أهدافهم وعيش حياة أكثر سعادة وصحة وتعويضهم بالقيم الغذائية التي يفتقرون اليها بسبب كثرة ما حولهم من خيارات سلبية بالإضافة الي تعليمهم كيفية توظيف تلك الحلول والتنوع باستخدامها.
                </p>
                <p>
                وبعد سنوات من البحث والدراسة، خلصت آدم إلى خمسة عوامل رئيسية تتلخص بهم مسببات الأمراض وهما: عادات الأكل غير الصحية – نقص العناصر الغذائية – السموم – الجراثيم – التوتر. وانه يسهل التغلب على 95% من أمراض العالم فقط بمعالجة هذه النقاط الخمس.
                </p>
                <p>
                ويتضح الآن أن الطعام المستهلك هو النقطة المحورية والعامل المهيمن والمفتاح الحقيقي، فمن خلال استبدال السموم المكررة والمتكرر استخدامها علي مدار اليوم مثل السكر الابيض وغيره بالأطوار الاصلية ذات القيمة العالية ، يتحقق ما يلي تلقائيا:
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Doctor} alt="about-section" />
              </div>
            </div>
            <div className="cards-container">
              <div className="card">
                <img src={pillCard} alt="" />
                <h4>
                  علاج نقص
                  <br />
                  العناصر الغذائية
                </h4>
                <p>
                  إمداد الجسم بإحتياجاته من العناصر الغذائية، مما يعالج نقص العناصر.الغذائية
                </p>
              </div>

              <div className="card">
                <img src={Immunity} alt="" />
                <h4>المناعة</h4>
                <br />
                <p>
                  عند تلبية جميع حاجات الجسم من العناصر الغذائية، تقوم المناعة بأداها الأمثل، مما يجعل الجسم أقوى ضد السموم والجراثيم.
                </p>
              </div>

              <div className="card">
                <img src={Suger} alt="" />
                <h4>
                  تقليل السموم
                </h4>
                <p>
                  تقليل السموم الغذائية المتكرر إستعلاكنا لها يومياً.
                </p>
              </div>

              <div className="card">
                <img src={Stress} alt="" />
                <h4>
                  تخفيف التوتر
                </h4>
                <p>
                  تناول الأطعمة الصحية يساعد في تخفيف الضغط النفسي و مستويات التوتر.
                </p>
              </div>
            </div>

            <div className="paragraph">
              <div className="paragraph-img">
                <img src={Running} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                وبتلك البساطة، يتم التغلب على معظم المسببات الرئيسية للأمراض، ونكون قد انتقلنا من الحالة المرضية إلى حالة التعافي والوقاية. وللتغلب على ما تبقى والتطور للوصول إلى المرحلة المتقدمة والحصول على الجسم المثالي، هناك ثلاث ركائز أساسية يجب اتباعها والتي تشكل معاً زوايا مثلث اللياقة البدنية: النشاط البدني – التغذية السليمة الكاملة – الراحة.
                </p>
                <p>
                ولكن بتحليل هذا المثلث، يتضح لنا أخيرا أسباب عدم تقدم الكثير بالرياضة بل وانتكاسهم، فالتركيز الأعمى على المغذيات الكبرى (كربوهيدرات – بروتين) دون مراعاة المغذيات الصغري والأهم  (معادن - فيتامينات - دهون صحية)  والتي توجه المغذيات الكبرى وتتحكم بسلوكها داخل الجسم، يؤدي لخلل جسيم بالنظام الغذائي وظهور نقاط ضعف به، مما يتسبب في:
                </p>
                <div className="small-card-wraper">
                <div className="small-card">
                    <h4>انهيار الضلع الثالث</h4>
                    <p>
                    زيادة الشد العضلي والأرق وخسارة النوم العميق والاسترخاء والاستشفاء مما يؤدي إلى ضمور العضلات وزيادة مستويات التوتر  والكورتيزون.
                    </p>
                  </div>

                  <div className="small-card">
                    <h4>انهيار الضلع الثاني</h4>
                    <p>
                    خلل التمثيل الغذائي ومقاومة امتصاص البروتين واختلال التوازن الهرموني وانخفاض معدل الحرق، ينتج عنه نقص إضافي بالطاقة ونقض البناء العضلي وزيادة الكتلة الدهنية.
                    </p>
                  </div>

                  <div className="small-card">
                    <h4>انهيار الضلع الأول</h4>
                    <p>
                    نقص في الطاقة والحيوية، وتراجع الأداء العضلي وعدم القدرة على ممارسة التمارين بشكل فعّال نقص النشاط المبذول يوميا.
                    </p>
                  </div>

                </div>
                <p>
                وبذلك نستنتج أيضا ان نوعية الطعام هو الزاوية الأهم بمثلث اللياقة أيضا.
                </p>
                <p>
                لذلك، كان لا بد من فكر جديد لتحطيم العقلية القديمة المخادعة، وبناء عقلية جديدة تستند إلى المعرفة والوعي، عقلية واعية ان طريق الصحة وطريق اللياقة هما طريق واحد لا يفترقان، وان الجسم المثالي يتم الوصول اليه من خلال اتباع طريق الصحة. وأن الصحة هي الأساس والمرض هو الانحراف والاستثناء. إنه نهج مختلف يقود إلى حياة أفضل بأسلوب أسهل.
                </p>
                <p>
                ومع ارتفاع موجة الأطعمة الصحية والقلق المتزايد حول الآثار الضارة لطرق إنتاج الطعام التقليدية، ظهرت العديد من الأطعمة الصحية البديلة. ورغم ذلك، يجب الاعتراف بأن جودتها، خاصة من حيث المذاق، لم ترقَ لتلبي ما يبحث عنه المستهلك وأصبح بين خيارين: إما القيمة الغذائية أو الطعم، وهذا لا يخفى على أحد. بالإضافة الي انه سيظل هناك صعوبة في الحصول على العناصر الغذائية التي تحتاجها اجسامنا من خلال النظام الغذائي وحده.
                </p>
              </div>
            </div>

            <div className="paragraph right">
              <div className="paragraph-content">
                <p>
                ولإيماننا بأن أي تغيير ناجح في حياة الإنسان يبدأ باستبدال قليل من العادات السلبية المتكررة بعادات أفضل. وهذا مبدأ أصيل تضعه الشركة في الاعتبار عندما يتعلق الأمر بتصحيح وتغيير حياة أفراد المجتمع نحو الأفضل دون التسبب في تعطيل أنماط حياتهم.
                </p>
                <p>
                :لذلك وضعت آدم بعض المعايير لاختيار المنتجات التي تقدمها لضمان مساهمتها في تحسين الصحة العامة مثل:
                </p>
                <p>
                  <span>قوة التأثير:</span>
                  ليكون لأقل عدد من المنتجات تأثير جذري بحياة الأشخاص.
                </p>
                <p>
                  <span>سوبر فود:</span>
                  لتوفر مستويات عالية التركيز للعديد من العناصر الغذائية الأساسية.
                </p>
                <p>
                  <span>الوقاية:</span>
                  تتمتع بالقدرة على تعزيز الوقاية من الأمراض.
                </p>
                <p>
                  <span>فوائد متعددة:</span>
                  تقدم فوائد صحية وعلاجية متعددة في وقت واحد.
                </p>
                <p>
                  <span>قدرات خاصة:</span>
                  تتمتع بقدرات خاصة مثل تعزيز الشعور بالطاقة وتحسين الأداء البدني والذهني وضبط الوزن وتوازن الهرمونات وتعزيز الشعور بالراحة.
                </p>
                <p>
                  <span>سهولة وتعدد الإستخدام:</span>
                  لتسهيل ادراجها بحياتنا اليومية وبذلك يمكننا استبدال الاضرار التراكمية من السموم المتناولة بتكرار عالي الي فوائد تراكمية.
                </p>
                <p>
                  <span>شامل:</span>
                  مناسب لمختلف الاحتياجات الغذائية وأنماط الحياة ويمكن توظيفه طبقا لنوع الهدف العام.
                </p>
                <p>
                  <span>الإستخدام الأمن:</span>
                  تناسب جميع الاعمار والحالات المختلفة مثل مرضي الضغط والسكر والقولون ومتبعي الحميات النباتية والحميات منخفضة السعرات.
                </p>
                <p>
                  <span>لذيذة:</span>
                  يجب ان يحبها المستهلك ويتلذذ بها
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Doctor} alt="about-section" />
              </div>
            </div>
          </div>
        </section>
        {/* section five */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-content">
                <h3>منتجاتنا</h3>
                <p>
                من العوامل التي ألهمتنا عند اختيار منتجات تخصصنا هي قدرتها على جذب الجمهور واستهلاكها، حتى لو لم يكونوا على دراية كاملة بأهميتها الغذائية. فدبس القصب وطحينة السمسم يحظون بقبول كبير لدى الجمهور بشكل طبيعي.
                </p>
                <p>
                ومع ذلك، قد لا يكون المستهلك على دراية بقيمتهما الغذائية كغذاء خارق، بل ومع انتشار الأنواع المغشوشة قد يكون علي يقين انه لا يشتريها بصورتها النقية ويعلم بأنها عادة ما يتم خلطها بعدة مواد اخري من قبل التجار، وهذا ما أدي الي انتشار الأنواع الرديئة بهدف تغطية الطلب الذي لم يكن بهدف القيمة الغذائية الفائقة من الأساس. لهذا، قررنا تقديم منتجاتنا في أنقى صورها، دون أي إضافات أو مواد حافظة. وبهذه الطريقة، يمكن للناس الاستمتاع بمذاقها اللذيذ وفوائدها الصحية العديدة، حتى لو لم يكونوا على دراية بقيمتها الغذائية.
                </p>
                <p>
                من هنا، بدأت رحلة من الابتكار المستمر، نزدهر فيها بتقديم منتجات لذيذة وفائقة القيمة الغذائية وخالية من السكر المضاف والمواد الحافظة والكيميائية، لتكون رفيقًا مخلصًا ولصحتك.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Products} alt="about-section" />
              </div>
            </div>

            <div className="paragraph left">
            <div className="paragraph-img">
                <img src={pillCard} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                وكان أولي الأبواب التي سلكتها آدم للتأثير الإيجابي بحياة المجتمع ان قامت بتركيز كامل جهودها على تطوير أهم البدائل للسلع الاستراتيجية `ذات التأثير الضار والتي يستهلكها الجمهور طوال يومه والعمل تقديم بديل لا يقاوم يفيض بالقيم الغذائية بمنتج واحد يشمل الطعم والجودة والقيمة الغذائية وتعدد الاستخدامات ليفتح بوابة لعالم من العافية والتمتع بالحياة. إنها رحلة صحة تبدأ بملعقة.
                </p>
                <p>
                وسرعان ما حظيت بالأفضلية لدى المستهلك من مختلف بقاع الأرض سواء كان نتيجة لتلبية منتجاتنا لأهدافهم الصحية او الجودة والطعم الخرافي والذي وضح مدي ثراء الصورة النقية لتلك المنتجات.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section six */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-content">
                <h3>آدم حول العالم</h3>
                <p>
                تلعب شركة آدم للمواد الغذائية دور هام في العديد من الأسواق العالمية، حيث تقوم بتصدير منتجاتها منذ عامها الأول إلى العديد من الدول المختلفة ذات الاشتراطات الصحية الأكثر تحديا حتي أصبحت المفضلة لتلك الشعوب مع نسبة احتفاظ بالعملاء سواء مستهلكين أو موزعين تصل لأكثر من 98%
                </p>
                <p>
                ساهمت ادم للمواد الغذائية في بناء العديد من العلامات التجارية العالمية وتعزيز مكانتها.
                </p>
                <p>
                فمن خلال التعاون المتبادل، اكتسبت تلك العلامات تفضيل شعوبهم وحققت نجاحات واسعة ومستدامة وكانت الأسرع نمواً.
                </p>
                <p>
                تنتج الشركات المالكة لتلك العلامات مجموعة واسعة من المنتجات الغذائية وبأعلى جودة ولكن عندما تذكر الدبس والطحينة، يجب ان تنتبه انه علي الرغم من قيمتهما الفائقة الا انها تحتاج خبرة ومهارة خاصة لإنتاجها بصورة تجمع بين الذوق والقيمة الغذائية والجودة العالية، لذا تطلب تمييز علامتهم بمنتج استثنائي وفريد الي تعاون مشترك لتقوم آدم بما تبرع به بعمليات الإنتاج والتعبئة وهم يقومون بتوزيعه داخل بلادهم تحت علامتهم الخاصة. وبذلك تمكنو من توفير نسخ فريدة لا يوفرها سوي آدم من خلالهم حتي استطاعوا اكتساب ولاء وثقة الشريحة المهتمة بالجودة العالية وأصبحت منتجاتنا بمثابة  رأس الحربة بمحفظتهم علي طول الخط.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={World} alt="about-section" />
              </div>
            </div>
            <Testimonials />
          </div>
        </section>
        {/* section seven */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-content">
                <h3>منهجيتنا الفريدة</h3>
                <p>
                سر قوتنا لا يكمن فقط في منتجاتنا الفريدة، بل في منهجيتنا التي تجعلنا مختلفين. فباتباعنا لاستراتيجية التركيز منذ بدايتنا اكسبنا سر صنعة فريد حتي استطعنا الوصول بجودة ومواصفات منتجاتنا الي اعلي الافاق الي ان أصبحت غير قابلة للمقارنة بأي نسخة أخري.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Design8} alt="about-section" />
              </div>
            </div>
            <div className="paragraph right">
            <div className="div-icons-wrapers">
                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={GoldenHeart} alt="golden-heart" />
                      <h4>الجودة الفائقة</h4>
                    </div>
                    <p>
                    التزام صارم بأعلى معايير الجودة في جميع مراحل الإنتاج.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={Fruits} alt="" />
                      <h4>الحفاظ على القيمة الغذائية</h4>
                    </div>
                    <p>
                    معالجة خاصة للحفاظ على القيمة الغذائية العالية.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={Magnet} alt="magnet" />
                      <h4>الاعتماد على الطرق الفيزيائية فقط</h4>
                    </div>
                    <p>
                    لا نستخدم الحرارة العالية أو أي مواد كيميائية تتعارض مع رسالتنا الصحية.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                    <img src={GoldenHeart} alt="golden-heart" />
                      <h4>النقاء</h4>
                    </div>
                    <p>
                    نقدم منتجاتنا بنقاء 100% وخالية تمامًا من السكريات والزيوت المضافة والمواد الحافظة والمواد الكيميائية.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={Innovation} alt="innovation" />
                      <h4>الابتكار والتطوير المستمر</h4>
                    </div>
                    <p>
                    لا نتوقف عن الابتكار. نحن نعمل باستمرار على تحسين منتجاتنا وتطوير منتجات جديدة لتلبية احتياجات عملائنا.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={Bottle} alt="" />
                      <h4>التعبئة والتغليف المدروس</h4>
                    </div>
                    <p>
                    نقدم منتجاتنا في عبوات مدروسة بعناية للحفاظ على نكهتها وجودتها والابتعاد كليا عن العبوات البلاستيكية المشتبه بصحيتها على المدي الطويل وتكرار الاستخدام.
                    </p>
                  </div>

                  <div className="div-icon">
                    <div className="icon-img">
                      <img src={Nature} alt="" />
                      <h4>الطبيعة</h4>
                    </div>
                    <p>
                    نستخدم أفضل المحاصيل تامة النضج الغير متلاعب بجيناتها ونعالجها بعناية وحرفية للحفاظ على قيمتها الغذائية دون أي من تلك المواد الكيميائية المريبة.
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </section>
        {/* section Eight */}
        <section className="paragraphs-wraper ar">
          <div className="container">
            <div className="paragraph">
              <div className="paragraph-content">
                <h3>مسؤولياتنا تجاه المجتمع</h3>
                <p>
                جميعنا في آدم ندرك أن لدينا مسؤولية كبيرة تجاه مجتمعنا والوعي العام لتحقيق رسالتنا. لذلك، نركز الان كامل جهودنا لزيادة الوعي الصحي لدى الشباب والأجيال القادمة، وتوفير الحلول المناسبة كالبدائل الصحية، بالإضافة إلى جهودنا لدعم الاقتصاد الوطني من خلال استخدام مواد خام وآلات ومستلزمات إنتاج محلية محلية الصنع.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={Team} alt="about-section" />
              </div>
            </div>
            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={Team2} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <h3>زيادة الوعي لدي الشباب والأجيال الناشئة</h3>
                <h5>1- التوعية</h5>
                <p>
                نعمل على توعية الشباب والأجيال القادمة بأهمية الحفاظ على الصحة النفسية والجسدية، واكسابهم الوعي والثقافة اللازمة لتغيير صحتهم نحو الأفضل بكل الطرق الممكنة مثل دعم ورعاية البرامج والأنشطة والفعاليات الصحية والرياضية.
                </p>
                <h5>2- دعم الإقتصاد المصري</h5>
                <p>
                جميع مستلزمات الانتاج والآلات التي نستخدمها صناعة مصرية خالصة بدءا من الخامات الغذائية وصولا الي العبوات الزجاجية والغطاء والكرتون والملصقات حتى الآلات المستخدمة خلال جميع مراحل الإنتاج هي صناعة مصرية خالصة دعما للاقتصاد المحلي وتأكيداً علي قدرة الصناعة والزراعة المصرية علي المنافسة العالمية علي أعلي المستويات.
                </p>
              </div>
            </div>
            <div className="paragraph">
              <div className="paragraph-content">
                <p>
                لم تكتفِ شركة آدم بتقديم منتجات غذائية ممتازة فحسب، بل سعت إلى بناء مجتمع صحي وواعٍ بأهمية التغذية السليمة. من خلال دعم البرامج الصحية والرياضية ورعاية الباحثين في مجال التغذية العلاجية، تهدف شركتنا إلى إحداث تغيير حقيقي في العادات الغذائية، وتمكين الناس من اتخاذ خيارات صحية ذكية.
                </p>
                <p>
                آدم هي حلم يتجسد على أرض الواقع، حلم يعبر عن آلاف الأطراف المشاركين من أطباء وخبراء تغذية ومزارعين وموظفين وعمال في مختلف المجالات متمثل بمنتج مثالي ونصيحة صادقة. هذا الحلم المشترك الذي يسعى لرفع الراية المصرية عاليًا في الأسواق العالمية، مؤكداً أن "صنع في مصر" ليس مجرد شعار، بل هو علامة على التميز والجودة. 
                </p>
                <p>
                ونجحت آدم في جعل منتجاتها تفوق المنتجات الأجنبية في جميع الأسواق التي دخلتها، وأصبحت مرادفًا للجودة والتميز.
                </p>
              </div>
              <div className="paragraph-img">
                <img src={AboutSection} alt="about-section" />
              </div>
            </div>
            <div className="paragraph left">
              <div className="paragraph-img">
                <img src={AboutSection} alt="about-section" />
              </div>
              <div className="paragraph-content">
                <p>
                ولا يزال طموح آدم يتطلع إلى آفاق أرحب. وتخطط لتطوير منتجات مبتكرة، واستكشاف أسواق جديدة، وبناء شبكة عالمية من الشركاء المهتمين بصحة الإنسان ورفاهيته وأن تصل منتجاتها إلى كل ركن بالعالم. وتركز الشركة جهودها حاليًا على الاستثمار في التجارب والاختبارات بالاستعانة بأهم خبراء التغذية وخبراء تحضير الأطعمة من حول العالم بهدف تقديم مجموعة واسعة من المنتجات الصحية بمذاق أروع من المتاح في الأسواق، وذلك لسد كامل الثغرات المسببة للأمراض ولتلبية جميع الأذواق.لا
                </p>
                <p>
                ندعوك للانضمام إلى عائلتنا، عائلة آدم، حيث يجمعنا فكر مختلف يستند إلى العلم والمعرفة، عقلية واعية ان الجسم المثالي يتم الوصول اليه من خلال اتباع طريق الصحة. وأن الصحة هي الأساس والمرض هو الانحراف والاستثناء.
                </p>
                <p>
                إنه نهج مختلف يقودنا لتغيير العالم نحو الأفضل.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterAr />
    </>
  );
};

export default AboutUsAr;
